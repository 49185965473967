import { useReactiveVar } from '@apollo/client';
import { first, partition } from 'lodash';
import { useTranslations } from 'next-intl';
import { BetslipTranslate } from 'translations/types';

import { BetType } from 'types/gql.bet';
import { betslipVars } from '../../betslipState';
import { BetslipError } from '../../types';

interface Output {
    errorMessage: string;
}

const useErrorMessage = (): Output => {
    const { betErrors, mode, validationErrors, inactiveOdds } =
        useReactiveVar(betslipVars);

    const translate = useTranslations('betting-betslip');

    const { maxStakeError, minStakeError, restrictionError } = validationErrors;

    if (
        !betErrors &&
        !maxStakeError &&
        !minStakeError &&
        !restrictionError?.length &&
        !inactiveOdds.length
    ) {
        return { errorMessage: '' };
    }

    const declineReason = betErrors?.declineReason || '';

    const betErrorRestrictions = splitErrorMessagesByType(
        betErrors?.restrictions || []
    );
    const validationErrorRestrictions = splitErrorMessagesByType(
        validationErrors.restrictionError || []
    );

    const declineContextRestriction = getRestrictionByBetType(
        mode,
        betErrorRestrictions
    );
    const validationRestriction = getRestrictionByBetType(
        mode,
        validationErrorRestrictions
    );

    const betError = declineContextRestriction || { message: declineReason };

    const fallbackError =
        !!(
            first(betErrorRestrictions.sportEventRestrictions) ||
            !!first(validationErrorRestrictions.sportEventRestrictions)
        ) && translate('betslip.Odd.Error.fallbackError');

    const inactiveOddError = getInactiveOddError({
        inactiveOdds,
        mode,
        translate,
    });

    const errors = [
        betError,
        validationRestriction,
        validationErrors.maxStakeError,
        validationErrors.minStakeError,
        inactiveOddError,
    ].filter((e) => e && e.message);

    return {
        errorMessage: errors.length
            ? errors[0]?.message || ''
            : fallbackError || '',
    };
};

export default useErrorMessage;

interface RestrictionByType {
    sportEventRestrictions: BetslipError[];
    generalRestrictions: BetslipError[];
}

function splitErrorMessagesByType(
    restrictions: BetslipError[]
): RestrictionByType {
    if (!restrictions || !restrictions.length) {
        return {
            sportEventRestrictions: [],
            generalRestrictions: [],
        };
    }

    const [sportEventRestrictions, generalRestrictions] = partition(
        restrictions,
        (r) => 'sportEventId' in r
    );

    return {
        sportEventRestrictions,
        generalRestrictions,
    };
}

function getRestrictionByBetType(
    betType: BetType,
    { sportEventRestrictions, generalRestrictions }: RestrictionByType
): BetslipError | undefined {
    const isSingle = betType === BetType.Single;

    return isSingle && !!first(sportEventRestrictions)
        ? first(sportEventRestrictions)
        : first(generalRestrictions);
}

function getInactiveOddError({
    inactiveOdds,
    mode,
    translate,
}: {
    inactiveOdds: string[];
    mode: BetType;
    translate: BetslipTranslate;
}) {
    if (!inactiveOdds.length) return { message: '' };

    if (mode === BetType.Single)
        return { message: translate('betslip.OddContainer.oddStatus') };

    return { message: translate('betslip.Odd.Error.betIsForbidden') };
}
