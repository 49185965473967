import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import { useAutofillAmount } from '../../hooks';
import useGetSuggests from '../useGetSuggests';
import SuggestItem from './SuggestItem';

const Suggests: FC = () => {
    const t = useTranslations('betting-betslip.betslip.BetslipFooter.TotalRow');

    const { suggests, withMaxButton } = useGetSuggests();
    useAutofillAmount({ suggests });

    return (
        <div className="flex gap-2">
            {suggests.map(({ amount }, index) => (
                <SuggestItem
                    amount={amount}
                    content={
                        index === suggests.length - 1 && !withMaxButton
                            ? t('maxButton')
                            : amount
                    }
                    key={amount}
                />
            ))}
        </div>
    );
};

export default memo(Suggests);
