import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import { BetType } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import { betslipVars } from '../betslipState';
import { BetslipError } from '../types';

interface Input {
    oddPath: string;
}

interface Output {
    errorMessage: string;
}

const useOddError = ({ oddPath }: Input): Output => {
    const t = useTranslations('betting-betslip.betslip.OddContainer');
    const {
        betErrors,
        validationErrors: { restrictionError },
        mode,
        inactiveOdds,
    } = useReactiveVar(betslipVars);

    if (mode === BetType.Single) {
        return {
            errorMessage: '',
        };
    }

    const isInactiveOdd = inactiveOdds.includes(oddPath);

    const betErrorRestriction = getSportEventRestriction(
        betErrors?.restrictions || [],
        oddPath
    );
    const generalRestriction = getSportEventRestriction(
        restrictionError || [],
        oddPath
    );
    const oddInactiveMessage = isInactiveOdd ? t('oddStatus') : '';

    return {
        errorMessage:
            oddInactiveMessage ||
            betErrorRestriction?.message ||
            generalRestriction?.message ||
            '',
    };
};

export default useOddError;

const getSportEventRestriction = (
    restrictionsErrors: BetslipError[],
    oddPath: string
): BetslipError | undefined => {
    if (!restrictionsErrors.length) return;

    return restrictionsErrors.find(
        (r) =>
            'sportEventId' in r &&
            idPathParser.split('Odd', oddPath).sportEventId === r.sportEventId
    );
};
