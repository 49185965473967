import * as GQL from 'types/gql.bet';

import * as Operations from './getApplicableFreebets.bet.gql';
import * as Apollo from '@apollo/client';
import { NextRouter, useRouter } from 'next/router';
import { QueryHookOptions, useQuery } from 'services/apollo/betting';

export type GetApplicableFreebetsQueryResult = Apollo.QueryResult<
    GQL.GetApplicableFreebets,
    GQL.GetApplicableFreebetsVariables
>;
import { WithApolloClientsContext } from 'services/apollo';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import { isSsr } from 'utils/isSsr';

import { get } from 'lodash';
import type {
    ExtendedApolloQueryOptions,
    PagePropsKeys,
} from 'services/apollo';
import { useBettingApolloClient as useGetApolloClient } from 'services/apollo/betting';
import getStubUseQuery from 'utils/getStubUseQuery';
import { toPromise } from 'services/apollo/utils/toPromise';

type Options = Omit<
    Apollo.QueryOptions<GQL.GetApplicableFreebetsVariables>,
    'query'
>;
type HookOptions = ExtendedApolloQueryOptions<
    GQL.GetApplicableFreebets,
    GQL.GetApplicableFreebetsVariables
>;
type MakeOptionsFunction = (ctx: WithApolloClientsContext) => Promise<Options>;

export function getServerPageGetApplicableFreebets(
    options?: Options | MakeOptionsFunction
) {
    return async (ctx: WithApolloClientsContext) => {
        const apolloLink = ctx.apolloBettingLinks;

        if (!apolloLink) {
            throw Error(
                'getServerPageGetApplicableFreebets miss apolloBettingLinks context!'
            );
        }

        const context =
            typeof options === 'function' ? await options(ctx) : options;

        return toPromise<GQL.GetApplicableFreebets>(
            Apollo.execute(apolloLink, {
                query: Operations.GetApplicableFreebets,
                context: context?.context,
                variables: context?.variables,
            }) as Apollo.Observable<
                Apollo.FetchResult<GQL.GetApplicableFreebets>
            >
        );
    };
}

const settledInitialData: Partial<Record<PagePropsKeys, boolean>> = {};

export function useGetApplicableFreebets(
    optionsFunc?: (router: NextRouter) => HookOptions
) {
    const { client } = useGetApolloClient();
    const pageProps = usePagePropsContext();
    const router = useRouter();
    const options = optionsFunc ? optionsFunc(router) : {};

    if (isSsr()) {
        return getStubUseQuery<GQL.GetApplicableFreebets>(
            pageProps,
            options.pagePropsKey
        );
    }

    if (
        !isSsr() &&
        options &&
        options.pagePropsKey &&
        pageProps.gsspData &&
        !settledInitialData[options.pagePropsKey]
    ) {
        const data = get(
            pageProps.gsspData,
            options.pagePropsKey
        ) as GQL.GetApplicableFreebets;

        if (data) {
            client?.writeQuery({
                query: Operations.GetApplicableFreebets,
                data,
                variables: { ...options?.variables, isClient: false },
            });

            settledInitialData[options.pagePropsKey] = true;
        }
    }
    return useQuery(Operations.GetApplicableFreebets, options);
}
