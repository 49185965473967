import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { first } from 'lodash';
import { useTranslations } from 'next-intl';
import { v4 as getIdempotencyId } from 'uuid';

import { SidebarTabId } from 'app-constants';
import { oddAcceptStrategyVar } from 'components/betting/GamblerSettings/gamblerState';
import { useRouterPush } from 'components/HybridRouter';
import { useGetMissingMatches, usePolling } from 'hooks';
import activeSidebarTabVar from 'layouts/BettingLayout/Sidebar/SidebarTabs/sidebarTabsState';
import isOnlineVar from 'layouts/MainLayout/isOnlineVar';
import { useBettingApolloClient } from 'services/apollo';
import { Feature, useFeatureFlags } from 'services/features';
import {
    Action,
    Category,
    send as sendAnalytic,
} from 'services/GoogleAnalytics';
import {
    Odd,
    PlaceBetInsurance,
    PlaceBetVariables,
    StakeMode,
} from 'types/gql.bet';
import idPathParser from 'utils/idPathParser/idPathParser';
import {
    clearOdds,
    setBetErrors,
    setBetId,
    setStatus,
} from '../../betslipActions';
import { betslipAnalyticVars } from '../../betslipAnalyticVars';
import { betslipVars } from '../../betslipState';
import { BetslipStatus, ErrorCodeMessages, ErrorCodes } from '../../constants';
import {
    convertBetStatus,
    getDeclineReason,
    getOddByFragment,
    getPlaceBetMeta,
    getRestrictionForState,
    redirectByRestriction,
} from '../../utils';
import { usePlaceBet } from './placeBet.bet';

interface Input {
    stake?: number;
    activeFreebetId?: string;
    activeInsurance?: PlaceBetInsurance | null;
}

const useMakePlaceBetHandler = ({
    stake,
    activeFreebetId,
    activeInsurance,
}: Input): VoidFunction => {
    const t = useTranslations('betting-betslip');
    const { client } = useBettingApolloClient();
    const { push } = useRouterPush();
    const { features } = useFeatureFlags();
    const isAppResponsibleGaming = features.isEnabled(
        Feature.AppResponsibleGaming
    );
    const idempotencyId = useRef<string>(getIdempotencyId());

    const {
        mode: betslipMode,
        stake: stakeValue,
        oddIds,
        betId,
        systemSize,
        status: betStatus,
    } = useReactiveVar(betslipVars);

    const oddAcceptStrategy = useReactiveVar(oddAcceptStrategyVar);

    const isOnline = useReactiveVar(isOnlineVar);

    const [placeBetMutation] = usePlaceBet();

    const { missingMatches } = useGetMissingMatches({
        oddIds,
        fetchPolicy: 'cache-only',
    });

    const getOdds = useCallback(() => {
        if (!missingMatches) return [];

        return oddIds.reduce<Odd[]>((acc, oddId) => {
            const odd = getOddByFragment(oddId, client);

            if (odd) acc.push(odd);

            return acc;
        }, []);
    }, [oddIds, client, missingMatches]);

    const getVariables = useCallback<() => PlaceBetVariables>(
        () => ({
            type: betslipMode,
            odds: getOdds().map(({ path, value: ratio }) => {
                const { sportEventId, marketId, oddId } = idPathParser.split(
                    'Odd',
                    path
                );

                return {
                    marketId,
                    matchId: sportEventId,
                    oddId,
                    ratio: +ratio,
                };
            }),
            stake: stake || Number(stakeValue),
            systemSize: [systemSize],
            oddAcceptStrategy,
            freebetId: activeFreebetId,
            idempotencyId: idempotencyId.current,
            stakeMode: StakeMode.Total,
            meta: getPlaceBetMeta(),
            insurance: activeInsurance,
        }),
        [
            activeFreebetId,
            activeInsurance,
            betslipMode,
            oddAcceptStrategy,
            getOdds,
            stake,
            stakeValue,
            systemSize,
        ]
    );

    const {
        startPolling: startRetryingPlaceBet,
        stopPolling: stopRetryingPlaceBet,
    } = usePolling({
        poller: () => {
            if (!isOnline) return;

            placeBet();
        },
        interval: 3000,
        incrementByTick: 3000,
    });

    useEffect(() => {
        if (betStatus === BetslipStatus.Error) {
            stopRetryingPlaceBet();
        }
    }, [betStatus, stopRetryingPlaceBet]);

    const placeBet = useCallback(() => {
        placeBetMutation({ variables: getVariables() })
            .then(({ data, errors }) => {
                if (errors?.length) throw new Error(errors[0].message);

                if (!data) throw new Error('Something went wrong');

                setBetId(data.placeBet.id);

                const status = convertBetStatus(data.placeBet.status);
                setStatus(status);

                if (status === BetslipStatus.Error) {
                    const restrictions =
                        first(data.placeBet.declineContext)?.restrictions || [];
                    const { declineReason } = data.placeBet;

                    const restrictionForState = getRestrictionForState(
                        restrictions,
                        t
                    );

                    redirectByRestriction(
                        restrictionForState,
                        push,
                        isAppResponsibleGaming
                    );

                    setBetErrors(
                        restrictionForState,
                        getDeclineReason(declineReason, t)
                    );

                    return;
                }

                if (status === BetslipStatus.Success) {
                    activeSidebarTabVar(SidebarTabId.BetHistory);
                    clearOdds();
                    setBetId('');
                }
            })
            .finally(() => {
                stopRetryingPlaceBet();
                idempotencyId.current = getIdempotencyId();
            })
            .catch((e) => {
                setStatus(BetslipStatus.Error);
                setBetId('');
                captureException(e);
                setBetErrors(
                    [],
                    `${t(ErrorCodeMessages[ErrorCodes.InternalError])}`
                );
            });
    }, [
        placeBetMutation,
        getVariables,
        t,
        push,
        isAppResponsibleGaming,
        stopRetryingPlaceBet,
    ]);

    return useCallback(() => {
        sendAnalytic({
            fieldsObject: {
                eventCategory: Category.Betslip,
                eventAction: Action.PlaceBet,
            },
            label: JSON.stringify({
                payload: betslipAnalyticVars(),
                betId,
                timestamp: Date.now(),
            }),
        });
        setStatus(BetslipStatus.Processing);

        if (!isOnline) {
            startRetryingPlaceBet();
        }

        placeBet();
    }, [betId, isOnline, startRetryingPlaceBet, placeBet]);
};

export default useMakePlaceBetHandler;
