import type { FC } from 'react';
import { memo } from 'react';

import Button from 'components/Button';
import { IconsPack } from 'components/PackIcon';
import usePlaceBetButton from './usePlaceBetButton';

const PlaceBet: FC = () => {
    const { loading, isDisabled, onPlaceBet, caption, iconId, color } =
        usePlaceBetButton();

    return (
        <Button
            type="button"
            size={Button.Size.Large}
            color={color}
            width={Button.Width.Full}
            isLoading={loading}
            onClick={onPlaceBet}
            isDisabled={isDisabled}
            iconId={iconId}
            pack={IconsPack.SpriteIcons}
            iconPosition="left"
            dataTest="place-bet"
            className="capitalize"
        >
            {caption}
        </Button>
    );
};

export default memo(PlaceBet);
