import { FC, memo } from 'react';
import cn from 'classnames';

import { BetslipBonusesProvider } from '../BetslipBonusesProvider';
import AmountInput from './AmountInput';
import ErrorMessage from './ErrorMessage';
import Suggests from './Suggests';
import SystemSizeSelector from './SystemSizeSelector';
import Totals from './Totals';
import useBetslipFooter from './useBetslipFooter';

const BetslipFooter: FC = () => {
    const { oddIds, isActiveFreebet } = useBetslipFooter();
    const scrollOverlay =
        'after:pointer-events-none after:content-[""] after:absolute after:top-[-48px] z-10 after:right-0 after:left-0 after:h-[48px] after:bg-gradient-to-t after:from-surface-dark  after:to-surface-dark/0';

    return (
        <div className={cn(scrollOverlay, 'relative mb-2 w-full px-2')}>
            {!!oddIds.length && <ErrorMessage />}
            <SystemSizeSelector />

            <BetslipBonusesProvider />

            {!isActiveFreebet && (
                <div className="mb-4">
                    <AmountInput />
                    <Suggests />
                </div>
            )}

            <Totals />
        </div>
    );
};

export default memo(BetslipFooter);
