import { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { FreebetFragment } from 'types/gql.bet';
import {
    handleActiveBetslipFreebetId,
    saveStake,
} from '../../../betslipActions';
import { betslipVars } from '../../../betslipState';

interface Input {
    amount: string;
    isSelected: boolean;
}

interface Output {
    onToggleFreebet: (freebet: FreebetFragment) => VoidFunction;
}

const useBetslipFreebetItem = ({ amount, isSelected }: Input): Output => {
    const { stake } = useReactiveVar(betslipVars);

    const isStakeUpdateNeeded = isSelected && stake !== amount;

    const onToggleFreebet = useCallback(
        (freebet: FreebetFragment) => () => {
            handleActiveBetslipFreebetId({
                freebetId: isSelected ? undefined : freebet.id,
                stake: isSelected ? '' : freebet.amount,
            });
        },
        [isSelected]
    );

    useEffect(() => {
        if (isStakeUpdateNeeded) {
            saveStake(amount);
        }
    }, [amount, isStakeUpdateNeeded]);

    return {
        onToggleFreebet,
    };
};

export default useBetslipFreebetItem;
