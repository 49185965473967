import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import useMobileSystemSizeSelector from './useMobileSystemSizeSelector';

const MobileSystemSizeSelector: FC = () => {
    const { sizes, oddCount, onSelect, systemSize } =
        useMobileSystemSizeSelector();
    const selectStyles =
        'typo-sm-2 flex appearance-none items-center rounded-default border-default border-solid border-surface-light bg-surface-dark py-2 pr-8 pl-2  outline-0 ';
    const isDisabled = sizes.length === 1;

    return (
        <div className="relative">
            <PackIcon
                key="arrowIcon"
                id="arrow-down"
                size={SvgIconSize.WH_4}
                className={cn(
                    'transition-transform',
                    'duration-300',
                    'pointer-events-none absolute right-4 top-[calc(50%+1px)] translate-x-[50%] translate-y-[-50%]',
                    !isDisabled ? 'fill-primary-white' : 'fill-grey-700'
                )}
                pack={IconsPack.SpriteIcons}
            />
            <select
                name="systemSize"
                id="111"
                onChange={onSelect}
                disabled={isDisabled}
                className={cn(
                    selectStyles,
                    'text-primary-white disabled:text-grey-700 disabled:opacity-100'
                )}
            >
                {sizes.map((size) => {
                    const isSelected = systemSize === size;

                    return (
                        <option
                            id={`${size}`}
                            value={size}
                            key={size}
                            selected={isSelected}
                        >
                            {`${size}/${oddCount}`}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default memo(MobileSystemSizeSelector);
