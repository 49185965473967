import type { ChangeEvent, KeyboardEvent, KeyboardEventHandler } from 'react';
import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useGetRestrictions } from 'gql/betting/queries/getRestrictions.bet';
import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import type { MaxBetRestriction } from 'types/gql.bet';
import { saveStake } from '../../betslipActions';
import { betslipVars } from '../../betslipState';
import {
    BetslipStatus,
    DEFAULT_MAX_BET,
    RestrictionsTypename,
} from '../../constants';
import { validateStake } from '../../utils';
import usePlaceBetButton from '../PlaceBet/usePlaceBetButton';

interface Output {
    currency: string;
    stake: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus: VoidFunction;
    loading: boolean;
    handleKeyDown: KeyboardEventHandler<HTMLInputElement>;
}

const useAmountInput = (): Output => {
    const { stake, oddIds, status } = useReactiveVar(betslipVars);
    const { onPlaceBet } = usePlaceBetButton();
    const { data: dataCurrency } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    const { data: restrictionsData } = useGetRestrictions(() => ({
        fetchPolicy: 'cache-only',
    }));

    const maxBet = useMemo(
        () =>
            restrictionsData?.restrictions.find(
                (restriction): restriction is MaxBetRestriction =>
                    restriction.__typename ===
                    RestrictionsTypename.MaxBetRestriction
            )?.maxBet || DEFAULT_MAX_BET,
        [restrictionsData?.restrictions]
    );

    const onChange = useCallback(
        ({ target }: ChangeEvent<HTMLInputElement>) => {
            if (!oddIds.length) return;

            if (/^(?![,.])\d*([.,]\d*)?$/.test(target.value)) {
                saveStake(
                    validateStake({
                        value: target.value,
                        maxValue: maxBet,
                    }) || ''
                );
            }
        },

        [oddIds.length, maxBet]
    );

    const onFocus = useCallback(() => saveStake(''), []);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key !== 'Enter') return;

            onPlaceBet();
        },

        [onPlaceBet]
    );

    return {
        currency: dataCurrency?.currency.toUpperCase() || '',
        stake,
        onChange,
        onFocus,
        loading: status === BetslipStatus.Processing,
        handleKeyDown,
    };
};

export default useAmountInput;
