import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { debounce } from 'lodash';

import { useAuthorization, useDeepMemo } from 'hooks';
import { BetType, ChangeGamblerBettingSlipVariables } from 'types/gql.bet';
import { betslipVars } from '../betslipState';
import { useChangeGamblerBettingSlip } from '../changeGamblerBettingSlip.bet';
import { useGetMissingOdds } from '../hooks';

const SyncWithPlaceBetVars: FC = () => {
    const { mode: betslipMode, stake, oddIds } = useReactiveVar(betslipVars);

    const { isAuthorized } = useAuthorization();

    const { odds } = useGetMissingOdds({ oddIds });

    const vars: ChangeGamblerBettingSlipVariables = useDeepMemo(() => {
        if (betslipMode !== BetType.Single) {
            return {
                betType: betslipMode,
                oddPaths: odds.map((odd) => ({
                    path: odd.id,
                    stake: '',
                })),
                stake: stake || '',
                systemSize: [odds.length || 1],
            };
        }

        return {
            betType: betslipMode,
            oddPaths: odds.map((odd) => ({
                path: odd.id,
                stake: stake || '',
            })),
            systemSize: [odds.length || 1],
        };
    }, [betslipMode, odds, stake]);

    const [changeGamblerBettingSlip] = useChangeGamblerBettingSlip({
        ignoreResults: true,
    });

    const changeGamblerBettingSlipHandler = useCallback(() => {
        if (!isAuthorized) return;

        changeGamblerBettingSlip({ variables: vars });
    }, [changeGamblerBettingSlip, isAuthorized, vars]);

    const changeGamblerBettingSlipHandlerDebounce = debounce(
        changeGamblerBettingSlipHandler,
        350
    );

    useEffect(() => {
        changeGamblerBettingSlipHandlerDebounce();

        return () => {
            changeGamblerBettingSlipHandlerDebounce.cancel();
        };
    }, [changeGamblerBettingSlipHandlerDebounce]);

    return null;
};

export default memo(SyncWithPlaceBetVars);
