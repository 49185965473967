import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import Empty from 'components/Empty';
import Spinner from 'components/Spinner';
import withClientMessages from 'hocs/withClientMessages';
import { Feature, useFeatureFlags } from 'services/features';
import ScrollbarsThumbVertical from '../ScrollbarsThumbVertical';
import PlaceBet from './BetslipFooter/PlaceBet';
import BetslipFooter from './BetslipFooter';
import BetslipHeader from './BetslipHeader';
import BetslipMode from './BetslipMode';
import BetslipOdd from './BetslipOdd';
import BetslipStub from './BetslipStub';
import useBetslip from './useBetslip';

interface Props {
    onClose?: () => void;
}

const Betslip: React.FC<Props> = ({ onClose }) => {
    const t = useTranslations('betting-betslip.betslip.Body');
    const { oddIds, loading } = useBetslip();

    const { features } = useFeatureFlags();

    const isEuro2024 = features.isEnabled(Feature.Euro2024);

    return (
        <div
            className={cn(
                'mt-auto flex h-[calc(100%-theme(spacing.header-group-offset))] flex-col justify-between sm:h-full md:mt-0'
            )}
        >
            <div className=" flex h-full flex-1 flex-col bg-surface-dark">
                <BetslipHeader onClose={onClose} />
                <BetslipMode />
                <div className="h-full">
                    <Scrollbars
                        hideTracksWhenNotNeeded={true}
                        universal={true}
                        renderView={(props) => (
                            <div
                                {...props}
                                className="flex flex-col gap-4 px-2 pb-8"
                            />
                        )}
                        renderThumbVertical={ScrollbarsThumbVertical}
                    >
                        {loading && (
                            <div className="flex h-full items-center justify-center">
                                <Spinner />
                            </div>
                        )}
                        {oddIds.length === 0 ? (
                            <Empty
                                icon={
                                    isEuro2024
                                        ? '/spa-assets/image/euro2024/albert/betslip-empty-hello-pose.png'
                                        : '/spa-assets/image/empty-state/betslip-empty.png'
                                }
                                message={t('emptyStateBet')}
                            />
                        ) : (
                            oddIds.map((id: string) => (
                                <BetslipOdd key={id} id={id} />
                            ))
                        )}
                    </Scrollbars>
                </div>
                <div className="mt-auto">
                    <BetslipFooter />
                </div>
            </div>
            <PlaceBet />
        </div>
    );
};

export default withClientMessages(Betslip, ['betting-betslip'], () => (
    <BetslipStub />
));
