import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { map } from 'lodash';

import { useGetInsurances } from 'gql/betting/queries/getInsurances.bet';
import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import { Feature, useFeatureFlags } from 'services/features';
import { BetType, Odd, Selection } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import { handleActiveBetslipFreebetId } from '../betslipActions';
import { betslipVars } from '../betslipState';
import { useGetMaxStake, useGetMissingOdds } from '../hooks';

interface Output {
    betType: BetType;
    selections: Selection[];
    minStake: number;
    isSkip: boolean;
    currency: string;
    isInsuranceAvailable: boolean;
    maxStake: number | undefined;
}

const useBetslipBonusesProvider = (): Output => {
    const {
        mode: betType,
        oddIds,
        minBet: minStake,
    } = useReactiveVar(betslipVars);

    const { features } = useFeatureFlags();

    const isEnableInsurance = features.isEnabled(Feature.BetslipInsurance);

    const { maxStake } = useGetMaxStake();

    const { odds } = useGetMissingOdds({ oddIds });

    const selections = useMemo(() => getSelections(odds), [odds]);

    const isSkip = selections.length === 0;

    const { data: dataCurrency } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    const { data: insurancesData } = useGetInsurances(() => ({
        variables: {
            filter: {
                onlyActual: true,
            },
        },
        skip: !isEnableInsurance || isSkip,
    }));

    const currency = dataCurrency?.currency || '';

    useEffect(() => {
        if (oddIds.length === 0) {
            handleActiveBetslipFreebetId({
                freebetId: undefined,
                stake: '',
            });
        }
    }, [oddIds]);

    return {
        betType,
        selections,
        isSkip,
        currency,
        minStake,
        isInsuranceAvailable: isEnableInsurance && !!insurancesData?.insurances,
        maxStake,
    };
};

const getSelections = (odds: Odd[]): Selection[] =>
    map(odds, ({ id: oddId, value: ratio, path }) => {
        const { sportEventId = '', marketId = '' } = idPathParser.split(
            'Odd',
            path
        );

        return {
            ratio,
            oddId,
            marketId,
            matchId: sportEventId,
        };
    });

export default useBetslipBonusesProvider;
