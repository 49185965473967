import { useEffect, useMemo, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { filter, isNil } from 'lodash';

import { POLLING_INTERVAL, TRADING_PROVIDER_ID } from 'app-constants';
import { useGetMinBet } from 'gql/betting/queries/getMinBet.bet';
import type { GetBetSuggest } from 'types/gql.bet';
import { StakeSuggesterAlgorithm } from 'types/gql.bet';
import { setMinBet } from '../betslipActions';
import { betslipVars } from '../betslipState';
import { DEFAULT_BET_VARIANT, DEFAULT_MAX_BET } from '../constants';
import { useGetMaxStake } from '../hooks';
import { useGetBetSuggest } from './getBetStakeSuggest.bet';

interface Output {
    suggests: GetBetSuggest['betStakeSuggest'];
    withMaxButton: boolean;
}

const useGetSuggests = (): Output => {
    const { oddIds } = useReactiveVar(betslipVars);
    const minBetRef = useRef<string | undefined>(DEFAULT_BET_VARIANT[0].amount);

    const withMaxButton = oddIds.some(
        (oddId) => !oddId.startsWith(TRADING_PROVIDER_ID)
    );

    const { maxStake: maxBet = DEFAULT_MAX_BET } = useGetMaxStake();

    const { data: minBetResponse } = useGetMinBet(() => ({
        pollInterval: POLLING_INTERVAL,
        fetchPolicy: 'cache-and-network',
    }));

    const minBet = minBetResponse?.minBet.minBet;
    minBetRef.current = minBet;

    const { data: suggestsResponse, error: suggestsError } = useGetBetSuggest(
        () => ({
            variables: {
                minStake: `${minBet}`,
                algorithm: StakeSuggesterAlgorithm.ByWl,
                suggestsCountLimit: DEFAULT_BET_VARIANT.length - 1,
            },
            pollInterval: POLLING_INTERVAL,
            skip: !minBet,
        })
    );

    const initialSuggests = useMemo(() => {
        if (suggestsResponse?.betStakeSuggest && !withMaxButton) {
            return [
                ...suggestsResponse.betStakeSuggest,
                { amount: `${maxBet}` },
            ];
        }

        return DEFAULT_BET_VARIANT;
    }, [withMaxButton, maxBet, suggestsResponse?.betStakeSuggest]);

    const [suggests, setSuggests] =
        useState<GetBetSuggest['betStakeSuggest']>(initialSuggests);

    useEffect(() => {
        if (
            !suggestsResponse ||
            !!suggestsError ||
            !suggestsResponse.betStakeSuggest.length
        ) {
            setMinBet(Number(minBetRef.current));

            return;
        }

        const { betStakeSuggest } = suggestsResponse;
        setSuggests(() => {
            if (isNil(maxBet)) return betStakeSuggest;

            const filteredSuggests = filter(
                betStakeSuggest,
                (i) => +i.amount < maxBet
            );

            if (!withMaxButton) {
                filteredSuggests.push({
                    amount: `${maxBet}`,
                });
            }

            return filteredSuggests;
        });
        setMinBet(Number(minBetRef.current));
    }, [withMaxButton, maxBet, suggestsError, suggestsResponse]);

    useEffect(() => {
        if (oddIds.length === 0) {
            setSuggests(initialSuggests);
        }
    }, [initialSuggests, oddIds.length]);

    return {
        suggests,
        withMaxButton,
    };
};

export default useGetSuggests;
