import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { BetType, Selection } from 'types/gql.bet';
import { betslipVars } from '../../betslipState';
import { BetslipStatus } from '../../constants';
import BetslipInsuranceRow from './BetslipInsuranceRow';
import useBetslipInsurance from './useBetslipInsurance';

interface Props {
    currency: string;
    betType: BetType;
    selections: Selection[];
}

const BetslipInsurance: FC<Props> = ({ currency, selections, betType }) => {
    const { onToggleInsurance, insurances, activeBetslipInsuranceId, stake } =
        useBetslipInsurance({
            betType,
            selections,
        });

    const { status } = useReactiveVar(betslipVars);

    if (!insurances.length || !stake) return null;

    return (
        <>
            {insurances.map(({ id, version, refundRules: { amount } }) => {
                const maxAmount = 'maxAmount' in amount ? amount.maxAmount : '';

                return (
                    <BetslipInsuranceRow
                        key={id}
                        currency={currency}
                        amount={amount}
                        maxAmount={maxAmount}
                        type={amount.type}
                        isSelected={activeBetslipInsuranceId === id}
                        isDisabled={status === BetslipStatus.Processing}
                        actionHandler={onToggleInsurance({ id, version })}
                    />
                );
            })}
        </>
    );
};

export default memo(BetslipInsurance);
