import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useGetRestrictions } from 'gql/betting/queries/getRestrictions.bet';
import { useAuthorization, useGetMissingMatches } from 'hooks';
import { useBettingApolloClient } from 'services/apollo';
import { BetType } from 'types/api.bet';
import { Selection } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import {
    clearBetErrors,
    handleActiveBetslipFreebetId,
    setSystemSize,
} from './betslipActions';
import {
    activeBetslipFreebetIdVar,
    activeBetslipInsuranceVar,
    betslipVars,
} from './betslipState';
import { BetslipStatus } from './constants';
import {
    useBetError,
    useMaxStakeError,
    useMinStakeError,
    usePollingBets,
    useRestrictionsError,
} from './hooks';
import { getOddByFragment, getSportEventByFragment } from './utils';

interface Output {
    oddIds: string[];
    isActiveFreebet: boolean;
    loading: boolean;
}

type Selections = {
    selections: Selection[];
};

const RESTRICTION_POLLING_INTERVAL = 10000;
const useBetslip = (): Output => {
    const { isAuthorized } = useAuthorization();

    const { client } = useBettingApolloClient();
    const {
        mode: betslipMode,
        oddIds,
        stake,
        systemSize,
        status,
        inactiveOdds,
    } = useReactiveVar(betslipVars);

    const activeFreebetId = useReactiveVar(activeBetslipFreebetIdVar);

    const { missingMatches, loading } = useGetMissingMatches({
        oddIds,
    });

    const { selections } = useMemo(() => {
        if (!missingMatches) return { selections: [] };

        return oddIds.reduce<Selections>(
            (acc, oddPath) => {
                const odd = getOddByFragment(oddPath, client);

                if (odd) {
                    const { sportEventId, marketId, oddId } =
                        idPathParser.split('Odd', odd.path);

                    const sportEvent = getSportEventByFragment(
                        sportEventId,
                        client,
                        ['providerId']
                    );

                    if (sportEvent) {
                        acc.selections.push({
                            marketId,
                            matchId: sportEventId,
                            oddId,
                            ratio: odd.value,
                            providerId: sportEvent.providerId,
                        });
                    }
                }

                return acc;
            },
            { selections: [] }
        );
    }, [oddIds, client, missingMatches]);

    const isSkipGetRestrictions = useMemo(() => {
        return (
            !isAuthorized ||
            !selections.length ||
            status === BetslipStatus.Processing ||
            inactiveOdds.length > 0
        );
    }, [isAuthorized, inactiveOdds.length, selections.length, status]);

    const { updateQuery, startPolling, stopPolling } = useGetRestrictions(
        () => ({
            variables: {
                betType: betslipMode,
                selections,
                systemSizes: [systemSize],
            },
            fetchPolicy: 'network-only',
            skip: isSkipGetRestrictions,
        })
    );

    // Note: need to fix bugs with polling https://github.com/apollographql/apollo-client/issues/7552
    useEffect(() => {
        if (isSkipGetRestrictions) {
            return stopPolling();
        }

        startPolling(RESTRICTION_POLLING_INTERVAL);
    }, [isSkipGetRestrictions, startPolling, stopPolling]);

    useMinStakeError();
    useMaxStakeError();
    useBetError();
    useRestrictionsError();
    usePollingBets();

    useEffect(() => {
        clearBetErrors();
    }, [oddIds, stake]);

    const isEmpty = oddIds.length === 0;

    useEffect(() => {
        if (isEmpty) {
            updateQuery(() => ({ restrictions: [] }));
        }
    }, [isEmpty, updateQuery]);

    useEffect(() => {
        if (betslipMode === BetType.System) return;

        setSystemSize(oddIds.length);
    }, [oddIds.length, betslipMode]);

    useLayoutEffect(() => {
        const freebetIsActive = activeBetslipFreebetIdVar();
        const activeInsurance = !!activeBetslipInsuranceVar();

        if (!freebetIsActive && !activeInsurance) return;

        handleActiveBetslipFreebetId({ stake: '' });
    }, [betslipMode]);

    return {
        oddIds,
        isActiveFreebet: Boolean(activeFreebetId),
        loading,
    };
};

export default useBetslip;
