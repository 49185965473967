import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import {
    BetType,
    GetApplicableInsurances,
    PlaceBetInsurance,
    Selection,
} from 'types/gql.bet';
import { handleActiveBetslipInsurance } from '../../betslipActions';
import { activeBetslipInsuranceVar, betslipVars } from '../../betslipState';
import { useGetApplicableInsurances } from '../getApplicableInsurances.bet';

interface Input {
    selections: Selection[];
    betType: BetType;
}
interface Output {
    onToggleInsurance: (insurance: PlaceBetInsurance) => VoidFunction;
    activeBetslipInsuranceId: string | undefined;
    insurances: GetApplicableInsurances['applicableInsurances'];
    stake: string;
}

const useBetslipInsurance = ({ selections, betType }: Input): Output => {
    const activeBetslipInsurance = useReactiveVar(activeBetslipInsuranceVar);
    const { stake } = useReactiveVar(betslipVars);

    const { data: insurances } = useGetApplicableInsurances(() => ({
        fetchPolicy: 'cache-and-network',
        variables: {
            betType,
            selections,
            betStake: stake,
        },
        skip: !stake,
    }));

    const activeBetslipInsuranceId = activeBetslipInsurance?.id;

    const onToggleInsurance = useCallback(
        (insurance: PlaceBetInsurance) => () => {
            handleActiveBetslipInsurance(
                activeBetslipInsuranceId === insurance.id ? null : insurance
            );
        },
        [activeBetslipInsuranceId]
    );

    return {
        onToggleInsurance,
        insurances: insurances?.applicableInsurances || [],
        activeBetslipInsuranceId,
        stake,
    };
};

export default useBetslipInsurance;
