import type { FC } from 'react';
import { memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import { useIsMobile } from 'components/utilities/MobileDetect';
import { BetType } from 'types/gql.bet';
import { betslipVars } from '../../betslipState';
import DesktopSystemSizeSelector from './DesktopSystemSizeSelector';
import MobileSystemSizeSelector from './MobileSystemSizeSelector';

const SystemSizeSelector: FC = () => {
    const { isMobile: isMobileDevice } = useIsMobile();
    const t = useTranslations('betting-global.global');
    const { mode } = useReactiveVar(betslipVars);

    if (mode !== BetType.System) {
        return null;
    }

    return (
        <div className=" my-2 flex items-center justify-between">
            <div className="text-grey-500 typo-sm-1">{t('SYSTEM')}</div>
            {isMobileDevice ? (
                <MobileSystemSizeSelector />
            ) : (
                <DesktopSystemSizeSelector />
            )}
        </div>
    );
};

export default memo(SystemSizeSelector);
