import { FC, memo, useCallback } from 'react';
import { useTranslations } from 'next-intl';

import { ModalsHash } from 'app-constants';
import { useRouterPush } from 'components/HybridRouter';
import useMakePlaceBetHandler from '../BetslipFooter/PlaceBet/useMakePlaceBetHandler';
import BetslipModal from '../BetslipModal';
import useNotEnoughBalanceModal from './useNotEnoughBalanceModal';

interface Props {
    isOpen: boolean;
    handleClose: VoidFunction;
    actualBalance: number;
    refillAmount: string;
    currencyCode: string;
    isShowPlaceBet: boolean;
    paymentMethodTitle?: string;
}

const NotEnoughBalanceModalContent: FC<Props> = ({
    isOpen,
    handleClose,
    actualBalance,
    refillAmount,
    currencyCode,
    isShowPlaceBet,
    paymentMethodTitle,
}) => {
    const t = useTranslations('betting-betslip.betslip.RefillBalanceModal');

    const placeBet = useMakePlaceBetHandler({ stake: actualBalance });

    const onPlaceBet = () => {
        placeBet();
        handleClose();
    };

    const { push } = useRouterPush();

    const openDeposit = useCallback(() => {
        handleClose();

        push(
            {
                hash: ModalsHash.Deposit,
                query: {
                    recommend_amount: refillAmount,
                    ...(paymentMethodTitle && {
                        recommend_method: paymentMethodTitle,
                    }),
                },
            },
            { replace: true }
        );
    }, [handleClose, paymentMethodTitle, push, refillAmount]);

    return (
        <BetslipModal
            isOpen={isOpen}
            okBtnText={t('refil')}
            handleClose={handleClose}
            handleSubmit={openDeposit}
            shallow={true}
            title={t('refillBalanceTitle')}
            footer={
                isShowPlaceBet ? (
                    <div
                        className="z-1 mt-10 cursor-pointer p-2 text-grey-500 typo-sm-1 hover:text-primary-white"
                        onClick={onPlaceBet}
                    >
                        {t('placeBetStake', {
                            stake: `${actualBalance} ${currencyCode}`,
                        })}
                    </div>
                ) : undefined
            }
            message={t('refillBalanceMessage', {
                balance: `${refillAmount} ${currencyCode}`,
            })}
        />
    );
};

const NotEnoughBalanceModal: FC = () => {
    const {
        isOpen,
        handleClose,
        actualBalance,
        refillAmount,
        currencyCode,
        isShowPlaceBet,
        paymentMethodTitle,
    } = useNotEnoughBalanceModal();

    if (!isOpen) return null;

    return (
        <NotEnoughBalanceModalContent
            isOpen={isOpen}
            handleClose={handleClose}
            actualBalance={actualBalance}
            refillAmount={refillAmount}
            currencyCode={currencyCode}
            isShowPlaceBet={isShowPlaceBet}
            paymentMethodTitle={paymentMethodTitle}
        />
    );
};

export default memo(NotEnoughBalanceModal);
