import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { floor, values } from 'lodash';

import { gamblerSettingsVar } from 'components/betting/GamblerSettings/gamblerState';
import { BetType } from 'types/api.bet';
import { formatDecimalOdd } from 'utils';
import { activeBetslipFreebetIdVar, betslipVars } from '../../betslipState';
import { PRECISION_FOR_AMOUNTS } from '../../constants';
import { getTotals, Totals } from '../../utils';

interface Output {
    totals: Omit<Totals, 'totalOdd'> & {
        totalOdd: string | number | null;
    };
    setCoefficientValue: Dispatch<SetStateAction<Record<string, number>>>;
}

const useTotals = (): Output => {
    const [coefficientValue, setCoefficientValue] = useState<
        Record<string, number>
    >({});

    const { oddFormat } = useReactiveVar(gamblerSettingsVar);

    const { mode, stake, systemSize } = useReactiveVar(betslipVars);

    const activeFreebetId = useReactiveVar(activeBetslipFreebetIdVar);
    const isActiveFreebet = mode !== BetType.System && Boolean(activeFreebetId);

    const totals = useMemo(() => {
        const oddValues = values(coefficientValue);

        const totalsResult = getTotals({
            isFreebet: isActiveFreebet,
            betType: mode,
            stake,
            oddValues,
            systemSize,
        });

        const { totalOdd: totalOddResult, totalWin: totalWinResult } =
            totalsResult;

        const totalOdd = totalOddResult
            ? formatDecimalOdd(totalOddResult, oddFormat)
            : null;

        const totalWin = floor(totalWinResult, PRECISION_FOR_AMOUNTS);

        return {
            ...totalsResult,
            totalOdd,
            totalWin,
        };
    }, [mode, coefficientValue, stake, systemSize, isActiveFreebet, oddFormat]);

    return {
        totals,
        setCoefficientValue,
    };
};

export default useTotals;
