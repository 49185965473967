import { FC } from 'react';
import { useTranslations } from 'next-intl';

import { DEFAULT_BET_VARIANT } from 'components/betting/Betslip/constants';
import Button from 'components/Button';
import Input from 'components/Forms/Input';
import Spinner from 'components/Spinner';
import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';

const betslipMode = ['SINGLE', 'EXPRESS', 'SYSTEM'] as const;
const outComesCount = 0;

interface Props {
    showSpinner?: boolean;
}

const BetslipStub: FC<Props> = ({ showSpinner = true }) => {
    const tBettingGlobal = useTranslations('betting-global.global');

    const { data: dataCurrency } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    const currency = dataCurrency?.currency.toUpperCase() || '';

    return (
        <div className="mt-auto flex flex-col justify-between xs:h-[calc(100%-theme(spacing.header-offset))] sm:h-full md:mt-0">
            <div className=" flex h-full flex-1 flex-col bg-surface-dark px-2">
                <div className="flex fill-primary-white px-2 py-3">
                    <div className="mx-auto flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-surface-light  text-primary-white typo-sm-1">
                            {outComesCount}
                        </div>
                        <div className="text-primary-white typo-sm-2">
                            {tBettingGlobal('outcomes', {
                                count: 2,
                            })}
                        </div>
                    </div>
                </div>
                <div className="mb-4 flex font-medium text-grey-700">
                    {betslipMode.map((mode) => (
                        <div
                            key={`betslip.${mode}`}
                            className="flex h-10 flex-1 items-center justify-center rounded-default bg-surface-middle typo-sm-1 first:bg-surface-light"
                        >
                            {tBettingGlobal(mode)}
                        </div>
                    ))}
                </div>
                <div className="flex flex-1 shrink-0 items-center justify-center pb-8">
                    {showSpinner && <Spinner />}
                </div>

                <div className="mt-auto">
                    <div className='relative z-10 mb-2 w-full after:pointer-events-none after:absolute after:inset-x-0 after:top-[-48px] after:h-[48px] after:bg-gradient-to-t after:from-surface-dark after:to-surface-dark/0 after:content-[""]'>
                        <div className="mb-4">
                            <Input
                                className="mb-2 w-full"
                                isDisabled={true}
                                iconsRight={[
                                    <div
                                        className="flex text-surface-dark typo-md-2"
                                        key={1}
                                    >
                                        {currency}
                                    </div>,
                                ]}
                                inputClasses="bg-surface-white-middle !text-surface-dark"
                            />
                            <div className="flex gap-2">
                                {replaceLast(DEFAULT_BET_VARIANT, {
                                    amount: '',
                                }).map(({ amount }) => (
                                    <div
                                        key={amount}
                                        className="flex w-full justify-center rounded-default border-default border-surface-light py-2 text-primary-white typo-sm-2"
                                    >
                                        {amount}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-3 flex items-center justify-between">
                            <div className="text-grey-500 typo-sm-1">
                                {tBettingGlobal('totalWin')}
                            </div>
                            <div className="flex items-center gap-1 rounded-default bg-surface-light px-2  py-1 text-primary-white typo-sm-2">
                                {`0 ${currency}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                type="button"
                size={Button.Size.Large}
                color={Button.Color.Orange}
                width={Button.Width.Full}
                isDisabled={true}
                className="capitalize"
            />
        </div>
    );
};

function replaceLast<T>(arr: Array<T>, newValue: T): Array<T> {
    return [...arr.slice(0, -1), newValue];
}

export default BetslipStub;
