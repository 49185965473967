import React, { FC, memo } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { Feature, useFeatureFlags } from 'services/features';
import ScrollbarsThumbVertical from '../../ScrollbarsThumbVertical';
import BetslipFreebets from './BetslipFreebets';
import BetslipInsurance from './BetslipInsurance';
import useBetslipBonusesProvider from './useBetslipBonusesProvider';

const BetslipBonusesProvider: FC = () => {
    const {
        isSkip,
        betType,
        selections,
        currency,
        minStake,
        isInsuranceAvailable,
        maxStake,
    } = useBetslipBonusesProvider();

    const { features } = useFeatureFlags();

    if (
        isSkip ||
        (!features.isEnabled(Feature.BetslipSkipFreebetsValidation) &&
            maxStake === undefined)
    ) {
        return null;
    }

    return (
        <Scrollbars
            universal={true}
            autoHeight={true}
            autoHeightMax="192px"
            renderView={(props) => <div {...props} className="py-2" />}
            renderThumbVertical={ScrollbarsThumbVertical}
        >
            {isInsuranceAvailable && (
                <BetslipInsurance
                    currency={currency}
                    selections={selections}
                    betType={betType}
                />
            )}
            <BetslipFreebets
                currency={currency}
                selections={selections}
                betType={betType}
                maxStake={maxStake}
                minStake={minStake}
            />
        </Scrollbars>
    );
};

export default memo(BetslipBonusesProvider);
