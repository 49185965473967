import { useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';

import { ModalsHash } from 'app-constants';
import { saveStake } from 'components/betting/Betslip/betslipActions';
import { betslipVars } from 'components/betting/Betslip/betslipState';
import useModal from 'components/modals/useModal';
import { useAuthorization } from 'hooks';

interface Output {
    makeHandlerTab: (amountSuggest: string) => () => void;
    getTabStyle: (amountSuggest: string, stake: string) => string;
    stake: string;
}

const useSuggestItem = (): Output => {
    const { oddIds, stake } = useReactiveVar(betslipVars);
    const { isAuthorized } = useAuthorization();
    const { openModal } = useModal();

    const suggestStyle = useMemo(
        () =>
            cn(
                'flex w-full cursor-pointer justify-center rounded-default border-default py-2 text-primary-white typo-sm-2'
            ),
        []
    );

    const getTabStyle = useCallback(
        (amountSuggest: string, stakeValue: string) =>
            cn(
                suggestStyle,
                amountSuggest === stakeValue
                    ? 'border-primary-white'
                    : 'border-surface-light'
            ),
        [suggestStyle]
    );

    const makeHandlerTab = useCallback(
        (amountSuggest: string) => () => {
            if (!isAuthorized) {
                openModal({ hash: ModalsHash.Registration });
            }

            if (oddIds.length === 0 || stake === amountSuggest) return;

            saveStake(amountSuggest);
        },
        [isAuthorized, openModal, stake, oddIds]
    );

    return {
        makeHandlerTab,
        getTabStyle,
        stake,
    };
};

export default useSuggestItem;
