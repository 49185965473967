import { FC, memo } from 'react';

import useSuggestItem from './useSuggestItem';

interface Props {
    amount: string;
    content: string;
}

const SuggestItem: FC<Props> = ({ amount, content }) => {
    const { getTabStyle, makeHandlerTab, stake } = useSuggestItem();

    return (
        <div
            className={getTabStyle(amount, stake)}
            onClick={makeHandlerTab(amount)}
            data-test="suggest-item"
        >
            {content}
        </div>
    );
};

export default memo(SuggestItem);
