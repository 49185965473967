import React from 'react';

import SubscriptionOdds from '../SubscriptionOdds';
import useMobileBetslipButtonWrapper from './useMobileBetslipButtonWrapper';

interface Props {
    children: ({
        count,
        totalOdds,
    }: {
        count: number;
        totalOdds: number;
    }) => React.ReactNode;
}

const MobileBetslipButtonWrapper: React.FC<Props> = ({ children }) => {
    const { updateCoefficientValue, totalOdds, oddIds } =
        useMobileBetslipButtonWrapper();

    return (
        <>
            {oddIds.map((oddId) => (
                <SubscriptionOdds
                    id={oddId}
                    key={oddId}
                    onUpdate={updateCoefficientValue}
                />
            ))}
            {children({ count: oddIds.length, totalOdds })}
        </>
    );
};

export default MobileBetslipButtonWrapper;
