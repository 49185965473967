import { RefObject, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { last } from 'lodash';

import { useClickOutside } from 'hooks';
import { setSystemSize } from '../../../betslipActions';
import { betslipVars } from '../../../betslipState';
import { getSystemSizesByIdsCount } from '../utils';

interface Output {
    isOpen: boolean;
    isDisabled: boolean;
    selectorRef: RefObject<any>;
    handleToggle: VoidFunction;
    makeHandleSystemSize: (value: number) => VoidFunction;
    systemSize: number;
    sizes: number[];
    oddCount: number;
}

const useDesktopSystemSizeSelector = (): Output => {
    const { oddIds, systemSize } = useReactiveVar(betslipVars);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const sizes = useMemo(
        () => getSystemSizesByIdsCount(oddIds.length),
        [oddIds.length]
    );

    const handleToggle = () => setIsOpen((p) => !p);
    const handleClose = () => setIsOpen(false);

    const makeHandleSystemSize = (value: number) => () => {
        setSystemSize(value);
        handleClose();
    };

    const selectorRef = useClickOutside(handleClose);

    useEffect(() => {
        setSystemSize(last(sizes) || 1);
    }, [sizes]);

    return {
        isOpen,
        isDisabled: sizes.length === 1,
        handleToggle,
        makeHandleSystemSize,
        selectorRef,
        systemSize,
        oddCount: oddIds.length,
        sizes,
    };
};

export default useDesktopSystemSizeSelector;
