import { useCallback } from 'react';
import cn from 'classnames';

import { typedReactMemo } from 'utils';

interface Props<T> {
    isChecked: boolean;
    label?: string;
    onChange: (value: T) => void;
    value: T;
    className?: string;
    labelPosition?: 'left' | 'right';
}

const RadioButton = <T,>({
    isChecked,
    label,
    onChange,
    value,
    className,
    labelPosition = 'left',
}: Props<T>) => {
    const radioButtonStyles = cn(
        'flex h-4 w-4 items-center justify-center rounded-full border-2',
        isChecked
            ? 'border-primary-orange-toxic'
            : 'border-grey-500 group-hover:border-primary-white'
    );
    const checkedStyles = cn('h-2 w-2 rounded-full bg-primary-orange-toxic');
    const labelStyles = cn(
        'typo-base',
        isChecked
            ? 'text-primary-orange-toxic'
            : 'text-grey-500 group-hover:text-primary-white ',
        labelPosition === 'left' ? 'order-none' : 'order-1'
    );

    const makeHandleChange = useCallback(
        (radioButtonValue: T) => () => onChange(radioButtonValue),
        [onChange]
    );

    return (
        <div
            className={cn(
                'group flex cursor-pointer items-center gap-x-2 rounded-default',
                'px-4 py-3 hover:bg-surface-light',
                className
            )}
            onClick={makeHandleChange(value)}
        >
            {label && <div className={labelStyles}>{label}</div>}
            <div className="p-1">
                <div className={radioButtonStyles}>
                    {isChecked && <div className={checkedStyles} />}
                </div>
            </div>
        </div>
    );
};

export default typedReactMemo(RadioButton);
