import { useReactiveVar } from '@apollo/client';

import { BetType } from 'types/gql.bet';
import { activeBetslipFreebetIdVar, betslipVars } from '../betslipState';

interface Output {
    oddIds: string[];
    isActiveFreebet: boolean;
    mode: BetType;
}

const useBetslipFooter = (): Output => {
    const { oddIds, mode } = useReactiveVar(betslipVars);
    const activeFreebetId = useReactiveVar(activeBetslipFreebetIdVar);

    return {
        mode,
        oddIds,
        isActiveFreebet: Boolean(activeFreebetId),
    };
};

export default useBetslipFooter;
