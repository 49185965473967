import type { FC } from 'react';
import { memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import { betslipVars } from '../../betslipState';
import SubscriptionOdds from '../../SubscriptionOdds';
import InfoRow from '../InfoRow';
import useTotals from './useTotals';

const Totals: FC = () => {
    const t = useTranslations('betting-global.global');

    const { stake, oddIds } = useReactiveVar(betslipVars);

    const {
        totals: { totalWin, totalOdd },
        setCoefficientValue,
    } = useTotals();

    const { data: dataCurrency } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    const currency = dataCurrency?.currency.toUpperCase() || '';

    return (
        <>
            {oddIds.map((path) => (
                <SubscriptionOdds
                    id={path}
                    key={path}
                    onUpdate={setCoefficientValue}
                />
            ))}

            <InfoRow
                title={t('BetslipFooter.TotalRow.totalOdd')}
                value={totalOdd}
                dataTest="total-odd"
            />

            <InfoRow
                title={t('totalWin')}
                value={`${totalWin} ${currency}`}
                isTotal={!!stake}
                dataTest="total-win"
            />
        </>
    );
};

export default memo(Totals);
