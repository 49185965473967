import React, { FC, memo } from 'react';
import cn from 'classnames';

import { CompareStatus } from 'app-constants';
import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';

interface Props {
    value: number;
    isActive: boolean;
    compareStatus: CompareStatus;
}

const BetslipOddCoefficient: FC<Props> = ({
    value,
    isActive,
    compareStatus,
}) => {
    const container =
        'typo-sm-2 flex items-center gap-1 rounded-default bg-surface-light py-1 px-2 text-primary-white';

    if (!isActive) {
        return (
            <div className={container}>
                <span className="h-4 w-4 text-center">-</span>
            </div>
        );
    }

    return (
        <span className={container}>
            {compareStatus !== CompareStatus.Equal && (
                <PackIcon
                    id="branch-more-up"
                    pack={IconsPack.SpriteIcons}
                    size={SvgIconSize.WH_4}
                    className={cn('animate-pulse', {
                        'fill-green-500':
                            compareStatus === CompareStatus.Greater,
                        'rotate-180 fill-red-700':
                            compareStatus === CompareStatus.Smaller,
                    })}
                />
            )}
            {value}
        </span>
    );
};

export default memo(BetslipOddCoefficient);
