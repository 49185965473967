import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';

interface Props {
    isOpen: boolean;
    title: string;
    message: string;
    okBtnText: string;
    handleClose: VoidFunction;
    handleSubmit?: VoidFunction;
    href?: NextLinkProps['href'];
    noBtnText?: string;
    footer?: JSX.Element;
    shallow?: NextLinkProps['shallow'];
}

const BetslipModal: FC<Props> = ({
    isOpen,
    title,
    href,
    message,
    okBtnText,
    noBtnText,
    handleClose,
    handleSubmit,
    footer,
    shallow,
}) => {
    const t = useTranslations('betting-global.global');

    if (!isOpen) return null;

    const overlay = cn(
        'fixed left-0 top-0 z-20 h-full w-full bg-gradient-to-t from-primary-black via-primary-black/95 to-primary-black/0 sm:absolute'
    );

    const gradient = cn(
        'after:absolute after:left-0 after:top-0 after:h-full after:w-full after:rotate-180 after:bg-radial-gradient-b-small after:from-primary-orange-toxic/25 after:to-primary-orange-toxic/0 after:bg-bottom after:content-[""]'
    );

    const wrapperClassName = 'z-10 w-full';

    return (
        <div className={overlay}>
            <div
                className={cn(
                    'absolute bottom-footer-offset flex w-full flex-col items-center bg-gradient-to-t from-primary-black to-primary-black/80 p-4 pb-10 text-center text-primary-white shadow-lg sm:bottom-0',
                    gradient
                )}
            >
                <PackIcon
                    id="icon-warning"
                    pack={IconsPack.SpriteIcons}
                    size={SvgIconSize.WH_8}
                    className="mb-6 fill-yellow-500"
                />
                <div className="mb-2">{title}</div>
                <div className="mb-6 text-grey-700 typo-sm-1">{message}</div>
                <div className="flex w-full gap-2">
                    <div className={wrapperClassName}>
                        <Button
                            size={Button.Size.Medium}
                            width={Button.Width.Full}
                            color={Button.Color.Grey}
                            isOutlined={true}
                            onClick={handleClose}
                        >
                            {noBtnText || t('no')}
                        </Button>
                    </div>

                    {href ? (
                        <div
                            className={wrapperClassName}
                            onClick={handleSubmit}
                        >
                            <Button
                                color={Button.Color.Green}
                                size={Button.Size.Medium}
                                width={Button.Width.Full}
                                href={href}
                                shallow={shallow}
                            >
                                {okBtnText}
                            </Button>
                        </div>
                    ) : (
                        <div className={wrapperClassName}>
                            <Button
                                color={Button.Color.Green}
                                size={Button.Size.Medium}
                                width={Button.Width.Full}
                                onClick={handleSubmit}
                            >
                                {okBtnText}
                            </Button>
                        </div>
                    )}
                </div>

                {footer}
            </div>
        </div>
    );
};

export default memo(BetslipModal);
