import React, { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { noop } from 'lodash';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { clearOdds } from '../betslipActions';
import BetslipOddAcceptSettings from '../BetslipOddAcceptSettings';
import { betslipVars } from '../betslipState';
import { BetslipStatus } from '../constants';
import OddCount from '../OddCount';

interface Props {
    onClose?: VoidFunction;
}

const BetslipHeader: FC<Props> = ({ onClose = () => {} }) => {
    const t = useTranslations('betting-global.global');

    const { status, oddIds } = useReactiveVar(betslipVars);
    const { until } = useResponsive();
    const oddsCount = oddIds.length;

    return (
        <div className="flex fill-primary-white px-4 py-3">
            <BetslipOddAcceptSettings />
            <div
                className="mx-auto flex items-center gap-2"
                data-test="betslip-stub"
            >
                <OddCount count={oddsCount} />
                <div className="text-primary-white typo-sm-2">
                    {t('outcomes', { count: 2 })}
                </div>
                <div
                    className="cursor-pointer fill-surface-light"
                    onClick={
                        status !== BetslipStatus.Processing ? clearOdds : noop
                    }
                >
                    <PackIcon id="close" pack={IconsPack.ActionIcons} />
                </div>
            </div>

            {until('sm') && (
                <div onClick={onClose} className="cursor-pointer">
                    <PackIcon id="arrow-down" pack={IconsPack.SpriteIcons} />
                </div>
            )}
        </div>
    );
};

export default memo(BetslipHeader);
