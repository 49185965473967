import type { FC } from 'react';
import { memo } from 'react';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import BetslipModal from '../BetslipModal';
import useBetslipOddAcceptChangeModal from './useBetslipOddAcceptChangeModal';

interface Props {
    isOpen: boolean;
    handleClose: VoidFunction;
    handleSubmit: VoidFunction;
}

const BetslipOddAcceptChangeModalContent: FC<Props> = ({
    isOpen,
    handleClose,
    handleSubmit,
}) => {
    const t = useTranslations('betting-betslip.betslip');

    return (
        <BetslipModal
            title={t('AcceptOddsChangeModal.oddsChanged')}
            message={t('AcceptOddsChangeModal.acceptAllQuestion')}
            okBtnText={t('AcceptNewCoefModal.acceptButton')}
            isOpen={isOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            footer={
                <div className="mt-4 text-grey-500 typo-sm-1">
                    {t.rich('AcceptOddsChangeModal.oddsStrategyHelpInfo', {
                        // NOTE: here we need the any type so as not to change the original translation by replacing {icon} => <icon></icon>
                        icon: (
                            <PackIcon
                                key="setting"
                                id="setting"
                                pack={IconsPack.ActionIcons}
                                size={SvgIconSize.WH_4}
                                className="inline fill-primary-white"
                            />
                        ) as any,
                    })}
                </div>
            }
        />
    );
};

const BetslipOddAcceptChangeModal: FC = () => {
    const { isOpen, handleClose, handleSubmit } =
        useBetslipOddAcceptChangeModal();

    if (!isOpen) return null;

    return (
        <BetslipOddAcceptChangeModalContent
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isOpen={isOpen}
        />
    );
};

export default memo(BetslipOddAcceptChangeModal);
