import * as GQL from 'types/gql.bet';

import * as Operations from './placeBet.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type PlaceBetMutationFn = Apollo.MutationFunction<
    GQL.PlaceBet,
    GQL.PlaceBetVariables
>;

/**
 * __usePlaceBet__
 *
 * To run a mutation, you first call `usePlaceBet` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceBet` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeBet, { data, loading, error }] = usePlaceBet({
 *   variables: {
 *      type: // value for 'type'
 *      odds: // value for 'odds'
 *      selections: // value for 'selections'
 *      stake: // value for 'stake'
 *      stakeMode: // value for 'stakeMode'
 *      systemSize: // value for 'systemSize'
 *      freebetId: // value for 'freebetId'
 *      oddAcceptStrategy: // value for 'oddAcceptStrategy'
 *      meta: // value for 'meta'
 *      insurance: // value for 'insurance'
 *      idempotencyId: // value for 'idempotencyId'
 *   },
 * });
 */
export function usePlaceBet(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.PlaceBet,
        GQL.PlaceBetVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<GQL.PlaceBet, GQL.PlaceBetVariables>(
        Operations.PlaceBet,
        options
    );
}
export type PlaceBetHookResult = ReturnType<typeof usePlaceBet>;
export type PlaceBetMutationResult = Apollo.MutationResult<GQL.PlaceBet>;
export type PlaceBetMutationOptions = Apollo.BaseMutationOptions<
    GQL.PlaceBet,
    GQL.PlaceBetVariables
>;
