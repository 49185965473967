import type { FC } from 'react';
import { memo } from 'react';

import PackIcon, { IconsPack } from 'components/PackIcon';
import useOddError from './useOddError';

interface Props {
    oddPath: string;
}

const OddError: FC<Props> = ({ oddPath }) => {
    const { errorMessage } = useOddError({ oddPath });

    if (!errorMessage) return null;

    return (
        <div
            className="flex w-full items-center gap-2 bg-surface-middle px-4 py-1 text-yellow-700 typo-xs-1"
            data-test="odd-error"
        >
            <PackIcon
                id="warning"
                pack={IconsPack.SpriteIcons}
                className="fill-yellow-700"
            />
            <div>{errorMessage}</div>
        </div>
    );
};

export default memo(OddError);
