import React from 'react';

const ScrollbarsThumbVertical: React.FC<any> = (props) => (
    <div
        {...props}
        className="!ml-[2.5px] !w-[2px] rounded-default bg-surface-light"
    />
);

export default ScrollbarsThumbVertical;
