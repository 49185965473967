import React, { useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { floor, reduce } from 'lodash';

import { useGetMissingMatches } from 'hooks';
import { betslipVars } from '../betslipState';
import { PRECISION_FOR_AMOUNTS } from '../constants';

interface Output {
    oddIds: string[];
    totalOdds: number;
    updateCoefficientValue: React.Dispatch<
        React.SetStateAction<Record<string, number>>
    >;
}

const useMobileBetslipButtonWrapper = (): Output => {
    const { oddIds } = useReactiveVar(betslipVars);

    useGetMissingMatches({
        oddIds,
    });

    const [coefficientValue, updateCoefficientValue] = useState<
        Record<string, number>
    >({});

    const totalOdds = useMemo(
        () =>
            reduce(
                coefficientValue,
                (acc, value) => {
                    return (
                        floor(acc * value, PRECISION_FOR_AMOUNTS) ||
                        Number(value)
                    );
                },
                0
            ),
        [coefficientValue]
    );

    return {
        oddIds,
        totalOdds,
        updateCoefficientValue,
    };
};

export default useMobileBetslipButtonWrapper;
