import { memo, useMemo } from 'react';
import { useTranslations } from 'next-intl';

import Input from 'components/Forms/Input';
import { useIsMobile } from 'components/utilities/MobileDetect';
import useAmountInput from './useAmountInput';

const AmountInput = () => {
    const { isMobile } = useIsMobile();
    const inputMode = isMobile ? 'decimal' : 'text';

    const { currency, stake, onChange, onFocus, loading, handleKeyDown } =
        useAmountInput();
    const t = useTranslations('betting-betslip.betslip.Input');

    const icon = useMemo(
        () => [
            <div
                className="flex text-surface-dark typo-md-2"
                key={1}
                data-test="input__currency"
            >
                {currency}
            </div>,
        ],
        [currency]
    );

    return (
        <Input
            className="mb-2 w-full"
            iconsRight={icon}
            onChange={onChange}
            value={stake}
            onFocus={onFocus}
            inputClasses="bg-surface-white-middle !text-surface-dark !outline-0 outline-offset-0 !border-opacity-0"
            isDisabled={loading}
            placeholder={t('placeholder')}
            inputMode={inputMode}
            onKeyDown={handleKeyDown}
        />
    );
};

export default memo(AmountInput);
