import type { FC } from 'react';
import cn from 'classnames';

interface Props {
    count: number | string;
    color?: Colors;
}

enum Colors {
    Surface = 'surface',
    Violet = 'violet',
}

const ColorClasses = {
    [Colors.Surface]: 'bg-surface-light',
    [Colors.Violet]: 'bg-violet-500',
};

const OddCount: FC<Props> & { Colors: typeof Colors } = ({ count, color }) => {
    return (
        <div
            className={cn(
                'flex h-6 w-6 items-center justify-center rounded-full text-primary-white  typo-sm-1',
                color ? ColorClasses[color] : ColorClasses[Colors.Surface]
            )}
        >
            {count}
        </div>
    );
};

OddCount.Colors = Colors;

export default OddCount;
