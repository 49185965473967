import { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { filter } from 'lodash';

import { BetType, FreebetFragment, Selection } from 'types/gql.bet';
import { handleActiveBetslipFreebetId } from '../../betslipActions';
import { activeBetslipFreebetIdVar } from '../../betslipState';
import { DEFAULT_MAX_BET } from '../../constants';
import { useGetApplicableFreebets } from '../getApplicableFreebets.bet';

interface Input {
    betType: BetType;
    selections: Selection[];
    maxStake: number | undefined;
    minStake: number;
}
interface Output {
    freebets: FreebetFragment[];
    activeBetslipFreebetId: string | undefined;
}

const useBetslipFreebets = ({
    minStake,
    maxStake = DEFAULT_MAX_BET,
    betType,
    selections,
}: Input): Output => {
    const { data, loading } = useGetApplicableFreebets(() => ({
        fetchPolicy: 'cache-and-network',
        variables: {
            betType,
            selections,
        },
        skip: betType === BetType.System,
    }));

    const freebets = useMemo(() => {
        return filter(
            data?.applicableFreebets,
            ({ amount }) => minStake <= +amount && +amount <= maxStake
        );
    }, [minStake, maxStake, data?.applicableFreebets]);

    const activeBetslipFreebetId = useReactiveVar(activeBetslipFreebetIdVar);

    const ids = freebets.map(({ id }) => id);

    useEffect(() => {
        if (loading) return;

        if (activeBetslipFreebetId && !ids.includes(activeBetslipFreebetId)) {
            handleActiveBetslipFreebetId({
                freebetId: undefined,
                stake: '',
            });
        }
    }, [activeBetslipFreebetId, ids, loading]);

    return {
        freebets,
        activeBetslipFreebetId,
    };
};

export default useBetslipFreebets;
