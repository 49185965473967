import type { FC } from 'react';
import { memo } from 'react';

import PackIcon, { IconsPack } from 'components/PackIcon';
import useErrorMessage from './useErrorMessage';

const ErrorMessage: FC = () => {
    const { errorMessage } = useErrorMessage();

    if (!errorMessage) return null;

    return (
        <div
            className="mb-2 flex items-center gap-1 break-words rounded-[4px] border-default border-yellow-700 px-4 py-2 text-yellow-700 typo-sm-1"
            data-test="error-message"
        >
            <PackIcon
                id="warning"
                pack={IconsPack.SpriteIcons}
                className="fill-yellow-700"
            />
            <div>{errorMessage}</div>
        </div>
    );
};

export default memo(ErrorMessage);
