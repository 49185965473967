import type { Dispatch, FC, SetStateAction } from 'react';
import { memo, useEffect } from 'react';
import type { FetchPolicy } from '@apollo/client';
import { omit } from 'lodash';

import { useSubscriptionPerOdd } from '../hooks';

type Props = {
    id: string;
    onUpdate: Dispatch<SetStateAction<Record<string, number>>>;
    fetchPolicy?: FetchPolicy;
};

const SubscriptionOdds: FC<Props> = ({ id, onUpdate, fetchPolicy }) => {
    const subsData = useSubscriptionPerOdd({ id, fetchPolicy });
    const { oddData, sportEvent, isActiveOdd } = subsData;

    useEffect(() => {
        if (!oddData?.value || !sportEvent?.id) {
            return;
        }

        const value = isActiveOdd ? Number(oddData.value) : Number(null);

        onUpdate((p) => ({ ...p, [sportEvent.id]: value }));

        return () => {
            onUpdate((p) => omit(p, sportEvent.id));
        };
    }, [oddData?.value, sportEvent?.id, isActiveOdd, onUpdate]);

    return null;
};

export default memo(SubscriptionOdds);
