import { FC, memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import type { FreebetFragment } from 'types/gql.bet';
import { betslipVars } from '../../../betslipState';
import { BetslipStatus } from '../../../constants';
import BetslipBonusRow from '../../BetslipBonusRow';
import useBetslipFreebetItem from './useBetslipFreebеtItem';

interface Props {
    freebet: FreebetFragment;
    isSelected: boolean;
    currency: string;
}

const BetslipFreebetItem: FC<Props> = ({ freebet, isSelected, currency }) => {
    const t = useTranslations('betting-global.global');

    const { status } = useReactiveVar(betslipVars);

    const { onToggleFreebet } = useBetslipFreebetItem({
        amount: freebet.amount,
        isSelected,
    });

    return (
        <BetslipBonusRow
            classNameIcon={isSelected ? 'fill-turquoise-700' : 'fill-grey-500'}
            classNameSwitcher="checked:bg-turquoise-700"
            iconId="freebets"
            key={freebet.id}
            amount={`${freebet.amount} ${currency}`.toUpperCase()}
            isSelected={isSelected}
            text={t('freebet', { count: 1 })}
            isDisabled={status === BetslipStatus.Processing}
            actionHandler={onToggleFreebet(freebet)}
        />
    );
};

export default memo(BetslipFreebetItem);
