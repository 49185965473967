import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { floor } from 'lodash';
import { useTranslations } from 'next-intl';

import { ModalsHash } from 'app-constants';
import Button from 'components/Button';
import { Color } from 'components/Button/types';
import useModal from 'components/modals/useModal';
import { useGetKyc } from 'gql/cms/queries/kyc/getKyc.cms';
import { useAuthorization } from 'hooks';
import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import { Feature, useFeatureFlags } from 'services/features';
import { BetType } from 'types/gql.bet';
import {
    activeBetslipFreebetIdVar,
    activeBetslipInsuranceVar,
    betslipVars,
} from '../../betslipState';
import { BetslipStatus, PRECISION_FOR_AMOUNTS } from '../../constants';
import { useGetMissingOdds } from '../../hooks';
import useMakePlaceBetHandler from './useMakePlaceBetHandler';

interface Output {
    isDisabled: boolean;
    iconId?: 'freebets' | 'insurance';
    caption: string;
    onPlaceBet: VoidFunction;
    loading: boolean;
    color: Color;
}

const usePlaceBetButton = (): Output => {
    const tBettingGlobal = useTranslations('betting-global.global');
    const tBettingBetslip = useTranslations('betting-betslip.betslip');

    const { isAuthorized } = useAuthorization();
    const { openModal } = useModal();
    const { features } = useFeatureFlags();
    const { data: kycResponse } = useGetKyc(() => ({
        skip: !isAuthorized,
    }));

    const isEnableInsurance = features.isEnabled(Feature.BetslipInsurance);
    const { mode, status, validationErrors, stake, inactiveOdds, oddIds } =
        useReactiveVar(betslipVars);

    const { data: dataCurrency } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));

    const currency = dataCurrency?.currency.toUpperCase() || '';

    const { odds } = useGetMissingOdds({ oddIds });

    const activeFreebetId = useReactiveVar(activeBetslipFreebetIdVar);

    const activeInsurance = useReactiveVar(activeBetslipInsuranceVar);

    const isActiveInsurance = !!activeInsurance && isEnableInsurance;

    const isActiveFreebet = mode !== BetType.System && Boolean(activeFreebetId);

    const iconBtn = isActiveFreebet ? 'freebets' : 'insurance';

    const iconId = isActiveFreebet || isActiveInsurance ? iconBtn : undefined;

    const onOpenAuthModal = () => {
        openModal({ hash: ModalsHash.Authorization });
    };

    const placeBet = useMakePlaceBetHandler({
        activeFreebetId,
        activeInsurance,
    });

    const onPlaceBet = useCallback(() => {
        if (kycResponse && kycResponse.kyc.status === 'unknown') {
            openModal({ hash: ModalsHash.KYC });

            return;
        }

        placeBet();
    }, [kycResponse, placeBet, openModal]);

    const getButtonCaption = (): string => {
        if (!isAuthorized) {
            return tBettingBetslip('BetslipFooter.TotalRow.signInBet');
        }

        const formattedStake = floor(+stake, PRECISION_FOR_AMOUNTS);
        const stakeText = `${formattedStake} ${currency}`;

        if (isActiveFreebet) {
            return `${tBettingGlobal('freebet', {
                count: 1,
            })} ${stakeText}`;
        }

        if (isActiveInsurance) {
            return tBettingBetslip('PlaceBet.insurancePlaceBet');
        }

        // Default case: User is authenticated but doesn't have a free bet or insurance
        return `${tBettingGlobal('placeBet')} ${stakeText}`;
    };

    const hasRestrictionsErrors =
        !!validationErrors.maxStakeError ||
        !!validationErrors.restrictionError?.length ||
        !!validationErrors.minStakeError;

    const isDisabled =
        !stake ||
        !odds.length ||
        hasRestrictionsErrors ||
        inactiveOdds.length > 0;

    return {
        isDisabled: isAuthorized && isDisabled,
        iconId,
        caption: getButtonCaption(),
        onPlaceBet: isAuthorized ? onPlaceBet : onOpenAuthModal,
        loading: status === BetslipStatus.Processing,
        color: getColorButton(isAuthorized, isActiveFreebet, isActiveInsurance),
    };
};

const getColorButton = (
    authenticated: boolean,
    isActiveFreebet: boolean,
    isActiveInsurance: boolean
) => {
    const { Green, Turquoise, Orange } = Button.Color;

    switch (true) {
        case !authenticated:
        case isActiveInsurance: {
            return Green;
        }

        case isActiveFreebet: {
            return Turquoise;
        }

        default: {
            return Orange;
        }
    }
};

export default usePlaceBetButton;
