import { FC, memo } from 'react';
import cn from 'classnames';

interface Props {
    title: string;
    value?: number | string | null;
    isTotal?: boolean;
    dataTest?: string;
}

const InfoRow: FC<Props> = ({ title, value, isTotal, dataTest }) => {
    const valueStyle =
        'typo-sm-2 flex items-center gap-1 rounded-default  py-1 px-2 text-primary-white';

    return (
        <div className="mb-3 flex items-center justify-between">
            <div
                className="text-grey-500 typo-sm-1"
                data-test="info-row__title"
            >
                {title}
            </div>
            <div
                className={cn(
                    valueStyle,
                    isTotal ? 'bg-green-700' : 'bg-surface-light'
                )}
                data-test={dataTest}
            >
                {value || '-'}
            </div>
        </div>
    );
};

export default memo(InfoRow);
