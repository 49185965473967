import React, { memo } from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { SportEventStatus } from 'types/gql.bet';
import BetslipOddCoefficient from '../BetslipOddCoefficient';
import OddError from './OddError';
import useBetslipOdd from './useBetslipOdd';

interface Props {
    id: string;
}

const BetslipOdd: React.FC<Props> = ({ id }) => {
    const {
        sportEvent,
        market,
        oddData,
        removeOddHandler,
        isActiveOdd,
        value,
        compareStatus,
    } = useBetslipOdd({
        id,
    });

    const container = 'px-4 bg-surface-middle';
    const ellipsis = 'truncate max-w-[170px]';

    if (!oddData || !sportEvent || !market || !value) return null;

    const { name } = oddData;

    const sportEventTitle = sportEvent.fixture.title;

    return (
        <div className="flex flex-col gap-[1px] ">
            <div
                className={cn(
                    container,
                    'relative flex items-center rounded-t-default py-3'
                )}
                data-test="betslip-odd__container"
            >
                <div className="w-full">
                    <div className="flex items-center justify-between text-primary-white typo-sm-2">
                        <span className="mr-2 line-clamp-2">{name}</span>
                        <div className="flex gap-2">
                            <BetslipOddCoefficient
                                value={value}
                                isActive={isActiveOdd}
                                compareStatus={compareStatus}
                            />
                            <div
                                onClick={removeOddHandler}
                                data-test="betslip-odd__remove"
                            >
                                <PackIcon
                                    id="close"
                                    key="close"
                                    pack={IconsPack.ActionIcons}
                                    className="cursor-pointer fill-surface-light"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={cn(
                            ellipsis,
                            'max-w-[150px] text-primary-white typo-sm-1'
                        )}
                    >
                        <span title={market.name}>{market.name}</span>
                    </div>
                </div>
            </div>

            <div
                className={cn(
                    container,
                    'flex items-center justify-between rounded-b-default py-2'
                )}
                title={sportEventTitle}
            >
                <span
                    className={cn(ellipsis, 'text-grey-500 typo-sm-1')}
                    data-test="betslip-odd__ttl"
                >
                    {sportEventTitle}
                </span>
                {[SportEventStatus.Live, SportEventStatus.Suspended].includes(
                    sportEvent.fixture.status
                ) && (
                    <span data-test="betslip-odd__live-icon">
                        <PackIcon
                            id="live"
                            pack={IconsPack.SpriteIcons}
                            className="fill-[#550000] text-red-300"
                        />
                    </span>
                )}
            </div>

            <OddError oddPath={id} />
        </div>
    );
};

export default memo(BetslipOdd);
