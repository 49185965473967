import { ChangeEvent, useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { last } from 'lodash';

import { BetType } from 'types/gql.bet';
import { setSystemSize } from '../../../betslipActions';
import { betslipVars } from '../../../betslipState';
import { getSystemSizesByIdsCount } from '../utils';

interface Output {
    onSelect: (event: ChangeEvent<HTMLSelectElement>) => void;
    systemSize: number;
    sizes: number[];
    oddCount: number;
}

const useMobileSystemSizeSelector = (): Output => {
    const { mode, oddIds, systemSize } = useReactiveVar(betslipVars);
    const sizes = useMemo(
        () => getSystemSizesByIdsCount(oddIds.length),
        [oddIds.length]
    );

    const onSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        setSystemSize(Number(event.target.value));
    };

    const isRenderSelector = mode === BetType.System;

    useEffect(() => {
        if (!isRenderSelector) return;

        setSystemSize(last(sizes) || 1);
    }, [isRenderSelector, sizes]);

    return {
        onSelect,
        systemSize,
        oddCount: oddIds.length,
        sizes,
    };
};

export default useMobileSystemSizeSelector;
