import { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import TabSegment from 'components/TabSegment';
import { useMount } from 'hooks';
import { BetType } from 'types/gql.bet';
import { changeBetslipMode } from '../betslipActions';
import { betslipVars } from '../betslipState';

const BetslipMode: React.FC = () => {
    const t = useTranslations('betting-global.global');
    const { oddIds, mode } = useReactiveVar(betslipVars);

    const oddIdsCount = oddIds.length;

    const handleExpressChange = useCallback(() => {
        changeBetslipMode(BetType.Express);
    }, []);

    const handleSystemChange = useCallback(() => {
        changeBetslipMode(BetType.System);
    }, []);

    const isSystemMode = mode === BetType.System;

    useMount(() => {
        if (oddIdsCount >= 2 && !isSystemMode) {
            changeBetslipMode(BetType.Express);
        }
    });

    useEffect(() => {
        if (oddIdsCount === 1) {
            return changeBetslipMode(BetType.Single);
        }

        if (oddIdsCount === 2) {
            return changeBetslipMode(BetType.Express);
        }
    }, [oddIdsCount]);

    return (
        <div className="mb-4 flex px-2">
            <TabSegment
                isActive={mode === BetType.Single}
                isDisabled={oddIdsCount !== 1}
                dataTest="bet-type--single"
            >
                {t('SINGLE')}
            </TabSegment>
            <TabSegment
                isActive={mode === BetType.Express}
                isDisabled={oddIdsCount < 2}
                onChange={handleExpressChange}
                dataTest="bet-type--express"
            >
                {t('EXPRESS')}
            </TabSegment>
            <TabSegment
                isActive={isSystemMode}
                isDisabled={oddIdsCount < 3}
                onChange={handleSystemChange}
                dataTest="bet-type--system"
            >
                {t('SYSTEM')}
            </TabSegment>
        </div>
    );
};

export default BetslipMode;
