import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';

import { CompareStatus } from 'app-constants';
import { gamblerSettingsVar } from 'components/betting/GamblerSettings/gamblerState';
import { useCompare } from 'hooks';
import type { MarketBase, MatchBase, Odd } from 'types/gql.bet';
import { formatDecimalOdd } from 'utils';
import {
    addInactiveOddsList,
    deleteInactiveOddsList,
    deleteOdd,
} from '../betslipActions';
import { betslipVars } from '../betslipState';
import { BetslipStatus } from '../constants';
import { useSubscriptionPerOdd } from '../hooks';

interface Input {
    id: string;
}

type OddValue = number | null;

interface Output {
    removeOddHandler: () => void | null;
    isActiveOdd: boolean;
    value: OddValue;
    sportEvent?: MatchBase;
    market?: MarketBase;
    oddData?: Odd;
    compareStatus: CompareStatus;
}

const useBetslipOdd = ({ id }: Input): Output => {
    const { status } = useReactiveVar(betslipVars);
    const { sportEvent, market, oddData, isActiveOdd } = useSubscriptionPerOdd({
        id,
    });

    const frozenValue = useRef<OddValue>(null);
    const { oddFormat } = useReactiveVar(gamblerSettingsVar);

    const isLoading = status === BetslipStatus.Processing;

    const path = oddData?.path;
    const value = Number(oddData?.value) || 0;
    const { compareStatus } = useCompare({ value });

    const removeOddHandler = useCallback(
        () => (path ? deleteOdd(path) : null),
        [path]
    );

    useEffect(() => {
        if (!path) return;

        if (!isActiveOdd) {
            addInactiveOddsList(path);
        }

        if (isActiveOdd) {
            deleteInactiveOddsList(path);
        }

        return () => {
            deleteInactiveOddsList(path);
        };
    }, [path, isActiveOdd]);

    useEffect(() => {
        if (isLoading) return;

        frozenValue.current = value;
    }, [value, isLoading]);

    // Note: need to remove odd if odd is not rendered
    useEffect(() => {
        if (!oddData || !sportEvent || !market || !value) {
            deleteOdd(id);
        }
    }, [id, market, oddData, sportEvent, value]);

    const returnedValue = formatDecimalOdd(
        isLoading && frozenValue.current !== null ? frozenValue.current : value,
        oddFormat
    ) as number;

    return {
        removeOddHandler,
        market,
        sportEvent,
        oddData,
        isActiveOdd,
        value: returnedValue || null,
        compareStatus,
    };
};

export default useBetslipOdd;
