import { FC, memo } from 'react';
import cn from 'classnames';

import PackIcon, { IconsPack } from 'components/PackIcon';
import SwitchCheckbox from 'components/SwitchCheckbox';

interface Props {
    isSelected: boolean;
    isDisabled: boolean;
    iconId: 'freebets' | 'insurance';
    amount?: string;
    text: string;
    actionHandler: VoidFunction;
    classNameIcon: string;
    classNameSwitcher: string;
}

const BetslipBonusRow: FC<Props> = ({
    isDisabled,
    isSelected,
    iconId,
    amount,
    text,
    actionHandler,
    classNameIcon,
    classNameSwitcher,
}) => {
    return (
        <div className="mb-2 flex w-full  items-center space-x-2 rounded-default bg-surface-middle px-3 py-2">
            <div className="flex items-center justify-center">
                <PackIcon
                    id={iconId}
                    pack={IconsPack.SpriteIcons}
                    className={cn('typo-base', classNameIcon)}
                />
            </div>
            <div
                className={cn(
                    'flex flex-auto items-center space-x-1 typo-base',
                    isSelected ? 'text-primary-white' : 'text-grey-500'
                )}
            >
                {text} {amount}
            </div>
            <div
                key={`${isSelected}`}
                className={cn({
                    'pointer-events-none cursor-default': isDisabled,
                })}
            >
                <SwitchCheckbox
                    isChecked={isSelected}
                    className={classNameSwitcher}
                    actionHandler={actionHandler}
                />
            </div>
        </div>
    );
};

export default memo(BetslipBonusRow);
