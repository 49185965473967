import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import {
    GamblerSetting,
    useChangeSettingAction,
} from 'components/betting/GamblerSettings';
import { oddAcceptStrategyVar } from 'components/betting/GamblerSettings/gamblerState';
import Button from 'components/Button';
import RadioButton from 'components/Forms/RadioButton';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { OddAcceptStrategy } from 'types/gql.bet';

const BetslipOddAcceptSettings: FC = () => {
    const t = useTranslations('betting-betslip.betslip.UpdatesSettings');
    const { changeSettingAction } = useChangeSettingAction();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { AlwaysAllowed, DeviationAllowed, GreaterAllowed } =
        OddAcceptStrategy;
    const buttons = [
        [AlwaysAllowed, 'acceptAll'],
        [GreaterAllowed, 'acceptHigher'],
        [DeviationAllowed, 'alwaysAsk'],
    ] as const;

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const oddAcceptStrategy = useReactiveVar(oddAcceptStrategyVar);

    const overlay = cn(
        'absolute left-0 top-0 z-20 h-full w-full bg-surface-dark/70'
    );

    const handleChangeOddAcceptStrategy = useCallback(
        (value: OddAcceptStrategy) => {
            changeSettingAction(GamblerSetting.ODD_ACCEPT_STRATEGY, value);
        },
        [changeSettingAction]
    );

    return (
        <>
            <div
                onClick={handleOpen}
                className="cursor-pointer"
                data-test="betslip-settings"
            >
                <PackIcon id="setting" pack={IconsPack.ActionIcons} />
            </div>
            <CSSTransition
                in={isOpen}
                classNames={{
                    enter: 'animate-fade-in',
                    exit: 'animate-fade-out',
                }}
                unmountOnExit={true}
                timeout={100}
            >
                <div className={overlay}>
                    <div className="absolute bottom-0 w-full rounded-t-large bg-surface-middle p-4 text-primary-white">
                        <div className="mb-8 flex justify-between">
                            <div className="max-w-[250px]">
                                <div>{t('updatesSettingsTitle')}</div>
                                <div className="mt-1 text-grey-700 typo-sm-1">
                                    {t('updatesSettingsDescription')}
                                </div>
                            </div>
                            <Button
                                iconId="close"
                                pack={IconsPack.ActionIcons}
                                color={Button.Color.Surface}
                                size={Button.Size.Small}
                                onClick={handleClose}
                            />
                        </div>

                        <div className="flex flex-col typo-sm-1">
                            {buttons.map(([value, text]) => (
                                <RadioButton
                                    key={text}
                                    className="justify-between px-0 pr-1"
                                    label={t(text)}
                                    isChecked={oddAcceptStrategy === value}
                                    value={value}
                                    onChange={handleChangeOddAcceptStrategy}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    );
};

export default memo(BetslipOddAcceptSettings);
