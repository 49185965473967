import type { FC } from 'react';
import { memo } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import cn from 'classnames';

import DropdownElement from 'components/DropdownElement';
import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import useDesktopSystemSizeSelector from './useDesktopSystemSizeSelector';

const DesktopSystemSizeSelector: FC = () => {
    const {
        isOpen,
        isDisabled,
        selectorRef,
        handleToggle,
        systemSize,
        makeHandleSystemSize,
        oddCount,
        sizes,
    } = useDesktopSystemSizeSelector();

    return (
        <div className="relative" ref={selectorRef}>
            <div
                className={cn(
                    'flex items-center rounded-default border-default border-solid p-1',
                    !isOpen ? 'border-surface-light' : 'border-primary-white'
                )}
            >
                <DropdownElement
                    id="currentValue"
                    actionHandler={isDisabled ? undefined : handleToggle}
                    isSelected={true}
                    customClassName="hover:bg-surface-dark"
                    iconsRight={[
                        <PackIcon
                            key="arrowIcon"
                            id="arrow-down"
                            size={SvgIconSize.WH_4}
                            className={cn(
                                'transition-transform',
                                'duration-300',
                                isOpen ? 'rotate-180' : 'rotate-0',
                                { 'fill-grey-700': isDisabled }
                            )}
                            pack={IconsPack.SpriteIcons}
                        />,
                    ]}
                    isDisabled={isDisabled}
                >
                    <span className="mr-3">{`${systemSize}/${oddCount}`}</span>
                </DropdownElement>
            </div>
            <div
                className={cn(
                    'absolute right-0 top-8 z-1 mt-2 w-[120px] rounded-default bg-surface-dark p-1 shadow-[0px_0px_24px_rgba(0,0,0,0.5)]',
                    {
                        hidden: !isOpen,
                    }
                )}
            >
                <Scrollbars
                    autoHeight={true}
                    autoHeightMax="150px"
                    universal={true}
                    renderThumbVertical={(props) => (
                        <div
                            {...props}
                            className=" rounded-default bg-surface-light"
                        />
                    )}
                >
                    {sizes.map((size) => {
                        const isSelected = systemSize === size;

                        return (
                            <DropdownElement
                                id={size}
                                actionHandler={makeHandleSystemSize(size)}
                                key={size}
                                isSelected={isSelected}
                            >
                                {`${size}/${oddCount}`}
                            </DropdownElement>
                        );
                    })}
                </Scrollbars>
            </div>
        </div>
    );
};

export default memo(DesktopSystemSizeSelector);
