import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import {
    GamblerSetting,
    useChangeSettingAction,
} from 'components/betting/GamblerSettings';
import { OddAcceptStrategy, RestrictionType } from 'types/gql.bet';
import { betslipVars } from '../betslipState';
import { BETSLIP_ODD_CHANGE_SESSION_KEY } from '../constants';

interface Output {
    isOpen: boolean;
    handleClose: VoidFunction;
    handleSubmit: VoidFunction;
}

const useBetslipOddAcceptChangeModal = (): Output => {
    const { betErrors } = useReactiveVar(betslipVars);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { changeSettingAction } = useChangeSettingAction();

    const selectionValueRestriction = betErrors?.restrictions.find(
        ({ type }) => type === RestrictionType.SelectionValue
    );

    const handleClose = useCallback(() => {
        sessionStorage.setItem(BETSLIP_ODD_CHANGE_SESSION_KEY, 'true');
        setIsOpen(false);
    }, []);

    const handleSubmit = useCallback(() => {
        changeSettingAction(
            GamblerSetting.ODD_ACCEPT_STRATEGY,
            OddAcceptStrategy.AlwaysAllowed
        );
        setIsOpen(false);
    }, [changeSettingAction]);

    useEffect(() => {
        const currentSessionStorage = sessionStorage.getItem(
            BETSLIP_ODD_CHANGE_SESSION_KEY
        );

        if (!selectionValueRestriction || currentSessionStorage) return;

        setIsOpen(true);
    }, [selectionValueRestriction]);

    return {
        isOpen,
        handleClose,
        handleSubmit,
    };
};

export default useBetslipOddAcceptChangeModal;
