import { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import type {
    RefundAmountAmountRule,
    RefundAmountPercentRule,
} from 'types/api.bet';
import { RefundAmountRuleType } from 'types/gql.bet';
import BetslipBonusRow from '../BetslipBonusRow';

interface Props {
    amount: RefundAmountAmountRule | RefundAmountPercentRule;
    maxAmount: string;
    isSelected: boolean;
    isDisabled: boolean;
    actionHandler: VoidFunction;
    currency: string;
    type: RefundAmountRuleType;
}

const BetslipInsuranceRow: FC<Props> = ({
    amount,
    maxAmount,
    isSelected,
    isDisabled,
    actionHandler,
    currency,
    type,
}) => {
    const t = useTranslations('betting-global.global');

    const getAmountValue = (): string | null => {
        if ('amount' in amount) {
            return amount.amount;
        }

        if ('percent' in amount) {
            return amount.percent;
        }

        return null;
    };

    const isAmountType = type === RefundAmountRuleType.Amount;

    const amountWithSymbol = `${Number(
        isAmountType ? getAmountValue() : maxAmount
    ).toFixed(2)} ${currency.toUpperCase()}`;

    const text = isAmountType
        ? t('insuranceAmount', {
              amount: amountWithSymbol,
          })
        : t('insurancePercent', {
              percent: `${Number(getAmountValue()).toFixed(0)}%`,
              amount: amountWithSymbol,
          });

    return (
        <BetslipBonusRow
            classNameIcon={cn(isSelected ? 'fill-green-700' : 'fill-grey-500')}
            classNameSwitcher="checked:bg-green-700"
            iconId="insurance"
            isSelected={isSelected}
            text={text}
            isDisabled={isDisabled}
            actionHandler={actionHandler}
        />
    );
};

export default memo(BetslipInsuranceRow);
