import React, { FC, memo } from 'react';
import { map } from 'lodash';

import { BetType, Selection } from 'types/gql.bet';
import BetslipFreebetItem from './BetslipFreebetItem';
import useBetslipFreebets from './useBetslipFreebets';

interface Props {
    currency: string;
    selections: Selection[];
    betType: BetType;
    maxStake: number | undefined;
    minStake: number;
}

const BetslipFreebets: FC<Props> = ({
    currency,
    selections,
    betType,
    minStake,
    maxStake,
}) => {
    const { freebets, activeBetslipFreebetId } = useBetslipFreebets({
        selections,
        betType,
        minStake,
        maxStake,
    });

    if (!freebets.length) return null;

    return (
        <>
            {map(freebets, (freebet) => (
                <BetslipFreebetItem
                    key={freebet.id}
                    freebet={freebet}
                    currency={currency}
                    isSelected={freebet.id === activeBetslipFreebetId}
                />
            ))}
        </>
    );
};

export default memo(BetslipFreebets);
