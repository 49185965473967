import { FC, memo, PropsWithChildren } from 'react';
import cn from 'classnames';

import type { AnalyticAttrs } from 'services/GoogleAnalytics';

type Props = PropsWithChildren<{
    isDisabled?: boolean;
    onChange?: () => void;
    isActive?: boolean;
    variant?: 'transparent' | 'opaque';
    analytic?: AnalyticAttrs;
    dataTest?: string;
}>;

const styles = {
    transparent: {
        active: 'bg-primary-white/20',
        inactive: 'bg-primary-white/10',
    },
    opaque: { active: '!bg-surface-light', inactive: 'bg-surface-middle' },
};

const TabSegment: FC<Props> = ({
    isDisabled,
    onChange,
    isActive,
    children,
    variant = 'opaque',
    analytic = {},
    dataTest,
}) => {
    const tab = cn(
        'flex h-10 flex-1 items-center justify-center font-medium typo-sm-1 '
    );

    const styleVariant = styles[variant];

    const active = cn(
        'cursor-pointer rounded-default text-primary-white',
        styleVariant.active
    );
    const inactive = cn(
        'cursor-pointer text-primary-white',
        styleVariant.inactive
    );
    const disabled = cn('cursor-not-allowed bg-surface-middle !text-grey-700');

    return (
        <div
            onClick={!isDisabled ? onChange : undefined}
            className={cn(tab, isActive ? active : inactive, {
                [disabled]: isDisabled,
            })}
            data-category={analytic['data-category']}
            data-action={analytic['data-action']}
            data-label={analytic['data-label']}
            data-test={`${dataTest} ${isActive && `${dataTest}--active`} ${
                isDisabled && `${dataTest}--disabled`
            }}`}
        >
            {children}
        </div>
    );
};

export default memo(TabSegment);
