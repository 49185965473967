import { useEffect, useMemo, useState } from 'react';
import getSymbol from 'currency-symbol-map';
import { floor } from 'lodash';

import { useGetMinBet } from 'gql/betting/queries/getMinBet.bet';
import { useGetPaymentHistory } from 'gql/cms/queries/getPaymentHistory.cms';
import { useAuthorization } from 'hooks';
import type { Bet, NotEnoughBalanceRestriction } from 'types/gql.bet';
import { RestrictionsTypename } from '../constants';
import { useOnBetslipBet } from '../hooks';

interface Output {
    isOpen: boolean;
    handleClose: VoidFunction;
    actualBalance: number;
    refillAmount: string;
    currencyCode: string;
    isShowPlaceBet: boolean;
    paymentMethodTitle?: string;
}

const useNotEnoughBalanceModal = (): Output => {
    const { isRegistrationFinished } = useAuthorization();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { data: response } = useOnBetslipBet({
        fetchPolicy: 'cache-only',
    });

    const { data: minBetResponse } = useGetMinBet(() => ({
        fetchPolicy: 'cache-only',
    }));

    const handleClose = () => setIsOpen(false);

    const data = response?.onUpdateBet;

    const notEnoughBalanceRestriction = useMemo(() => {
        if (!data?.declineContext.length) return;

        return getNotEnoughBalanceRestriction(data.declineContext);
    }, [data]);

    const actualBalance = notEnoughBalanceRestriction
        ? floor(Number(notEnoughBalanceRestriction.actualBalance))
        : 0;

    const refillAmount = useMemo(() => {
        if (!notEnoughBalanceRestriction || !data) return '0';

        const { stake } = data;

        return `${Number(stake) - actualBalance}`;
    }, [notEnoughBalanceRestriction, data, actualBalance]);

    useEffect(() => {
        if (!notEnoughBalanceRestriction) return;

        setIsOpen(true);
    }, [notEnoughBalanceRestriction]);

    const currencyCode = getSymbol(data?.currencyCode || '') || '';

    const isShowPlaceBet = useMemo(() => {
        return Number(minBetResponse?.minBet.minBet) <= actualBalance;
    }, [actualBalance, minBetResponse?.minBet.minBet]);

    const { data: paymentHistoryData } = useGetPaymentHistory(() => ({
        skip: !isRegistrationFinished,
    }));

    const paymentMethodTitle =
        paymentHistoryData?.paymentHistory?.transactions[0]?.paymentMethod
            ?.title;

    return {
        isOpen,
        handleClose,
        actualBalance,
        refillAmount,
        currencyCode,
        isShowPlaceBet,
        paymentMethodTitle,
    };
};

export function getNotEnoughBalanceRestriction(
    declineContext: Bet['declineContext']
): NotEnoughBalanceRestriction | undefined {
    const { restrictions } = declineContext[0];

    return restrictions.find(
        (restriction): restriction is NotEnoughBalanceRestriction =>
            restriction.__typename ===
            RestrictionsTypename.NotEnoughBalanceRestriction
    );
}

export default useNotEnoughBalanceModal;
