import * as GQL from 'types/gql.bet';

import * as Operations from './changeGamblerBettingSlip.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type ChangeGamblerBettingSlipMutationFn = Apollo.MutationFunction<
    GQL.ChangeGamblerBettingSlip,
    GQL.ChangeGamblerBettingSlipVariables
>;

/**
 * __useChangeGamblerBettingSlip__
 *
 * To run a mutation, you first call `useChangeGamblerBettingSlip` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGamblerBettingSlip` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGamblerBettingSlip, { data, loading, error }] = useChangeGamblerBettingSlip({
 *   variables: {
 *      betType: // value for 'betType'
 *      oddPaths: // value for 'oddPaths'
 *      stake: // value for 'stake'
 *      systemSize: // value for 'systemSize'
 *   },
 * });
 */
export function useChangeGamblerBettingSlip(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.ChangeGamblerBettingSlip,
        GQL.ChangeGamblerBettingSlipVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.ChangeGamblerBettingSlip,
        GQL.ChangeGamblerBettingSlipVariables
    >(Operations.changeGamblerBettingSlip, options);
}
export type ChangeGamblerBettingSlipHookResult = ReturnType<
    typeof useChangeGamblerBettingSlip
>;
export type ChangeGamblerBettingSlipMutationResult =
    Apollo.MutationResult<GQL.ChangeGamblerBettingSlip>;
export type ChangeGamblerBettingSlipMutationOptions =
    Apollo.BaseMutationOptions<
        GQL.ChangeGamblerBettingSlip,
        GQL.ChangeGamblerBettingSlipVariables
    >;
